/**
 * Logo
 */

.Logo {
    color: $white;

    svg {
        width: 130px;
        height: 92px;
    }

    &:active,
    &:visited,
    &:hover {
        color: $white;
    }
}
