/**
 * How we do
 */

.Block--howwedo {
    margin: u(145, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}

.Howwedo-title {
    @include font-size(32);

    max-width: 380px;
    line-height: 42px;

    .has-columns & {
        max-width: 570px;
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u($spacing-unit--xl);
        }
    }

    .PagePage & {
        @include font-size(22);

        color: $cc-blue;
        font-weight: bold;
        line-height: 1.36;
        font-family: $font-lato;
    }

    strong {
        color: $white;
        padding: 0 12px;
        background-color: $cc-blue;

        /* Needs prefixing */
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;

        &.on-newline {
            left: -12px;
            position: relative;
        }
    }
}

.Media-links {
    display: flex;
    flex-direction: column;

    margin-top: u($spacing-unit--sm);
}

.Howwedo-link {
    margin-top: u($spacing-unit--sm);

    .has-columns & {
        margin-top: u($spacing-unit--lg);
    }
}

.Howwedo-items {

    .has-columns & {
        display: flex;
        flex-wrap: wrap;

        @include mq($from: $viewport--md) {
            margin-left: u($spacing-unit--lg * -1);
        }

        @include mq($from: $viewport--lg) {
            margin-left: -130px;
        }
    }
}

.Howwedo-item {
    position: relative;

    .has-rows & {
        display: flex;
    }

    .has-columns & {
        text-align: center;

width: 100%;
        @include mq($from: $viewport--md) {
            padding-left: u($spacing-unit--lg);
            width: (100% / 3);
        }

        @include mq($from: $viewport--lg) {
            padding-left: 130px;
        }
    }

    body:not(.HomePage) & {
        display: flex;
    }

    & + .Howwedo-item {

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit);
            padding-top: u($spacing-unit);
        }

        .has-rows & {
            margin-top: u($spacing-unit);
            padding-top: u($spacing-unit);
        }
    }
}

.HowwedoItem-icon {
    width: 60px;
    flex-shrink: 0;

    svg {
        width: 35px;
        height: 35px;
        color: #444444;
    }

    .has-columns & {
        display: inline-block;
        margin-bottom: u($spacing-unit--xs);
    }
}

.HowwedoItem-content {
    margin: auto;
}

.HowwedoItem-title {
    @include font-size(20);

    color: $black;
    font-weight: bold;
    font-family: $font-lato;
    margin-bottom: u($spacing-unit--sm);
}

.HowwedoItem-text {

    @include mq($from: $viewport--md) {

        .has-columns & {
            position: relative;
        }
    }

    .Howwedo-item:not(:first-child) & {

        &::before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            height: 2px;
            width: 100%;
            background-image: $cc-blue--image;

            .has-columns & {

                @include mq($from: $viewport--md) {
                    left: u($spacing-unit * -1 - 2);
                    height: 100px;
                    width: 2px;
                }

                @include mq($from: $viewport--lg) {
                    left: -66px;
                }
            }
        }
    }
}
