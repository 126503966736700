/**
 * Vacancy
**/

.Vacancies {

    @include mq($until: $viewport--md - 1) {
        width: 100%;
        margin-top: u($spacing-unit--lg * 1.5);
    }

    @include mq($from: $viewport--md) {
        flex-shrink: 1;
        margin-left: u($spacing-unit--lg);
    }
}

.Vacancy {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 100%;
    position: relative;
    text-decoration: none;
    transition: padding .3s;

    @include mq($from: $viewport--md) {
        width: 360px;
    }

    @include mq($from: $viewport--lg) {
        width: 520px;
    }

    &:hover {
        padding: u(0, $spacing-unit--xs * 1.2);
    }

    svg {
        width: 28px;
        height: 16px;
    }

    & + .Vacancy {
        margin-top: u($spacing-unit);
        padding-top: u($spacing-unit);

        &::before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            height: 2px;
            width: 100%;
            background-image: $cc-blue--image;
        }
    }
}

.Vacancy-left {
    margin-right: u($spacing-unit--lg);
}

.Vacancy-title {
    @include font-size(20);

    margin: 0;
    color: $black;
    font-weight: bold;
    font-family: $font-lato;
}

.Vacancy-subtitle {
    @include font-size(15);

    color: $black;
    line-height: 1.67;
    font-family: $font-lato;
}