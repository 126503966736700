/**
 * Spacing: Small
 */

@include mq($from: $viewport--sm, $until: $viewport--md - 1) {

    // Top

    /// None

    .u-sm-marginTn {
        margin-top: 0 !important;
    }

    .u-sm-paddingTn {
        padding-top: 0 !important;
    }


    /// Extra small

    .u-sm-marginTxs {
        margin-top: u($spacing-unit--xs) !important;
    }

    .u-sm-paddingTxs {
        padding-top: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-sm-marginTsm {
        margin-top: u($spacing-unit--sm) !important;
    }

    .u-sm-paddingTsm {
        padding-top: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-sm-marginTmd {
        margin-top: u($spacing-unit) !important;
    }

    .u-sm-paddingTmd {
        padding-top: u($spacing-unit) !important;
    }


    /// Large

    .u-sm-marginTlg {
        margin-top: u($spacing-unit--lg) !important;
    }

    .u-sm-paddingTlg {
        padding-top: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-sm-marginTxl {
        margin-top: u($spacing-unit--xl) !important;
    }

    .u-sm-paddingTxl {
        padding-top: u($spacing-unit--xl) !important;
    }



    // Right

    /// None

    .u-sm-marginRn {
        margin-right: 0 !important;
    }

    .u-sm-paddingRn {
        padding-right: 0 !important;
    }


    /// Extra small

    .u-sm-marginRxs {
        margin-right: u($spacing-unit--xs) !important;
    }

    .u-sm-paddingRxs {
        padding-right: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-sm-marginRsm {
        margin-right: u($spacing-unit--sm) !important;
    }

    .u-sm-paddingRsm {
        padding-right: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-sm-marginRmd {
        margin-right: u($spacing-unit) !important;
    }

    .u-sm-paddingRmd {
        padding-right: u($spacing-unit) !important;
    }


    /// Large

    .u-sm-marginRlg {
        margin-right: u($spacing-unit--lg) !important;
    }

    .u-sm-paddingRlg {
        padding-right: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-sm-marginRxl {
        margin-right: u($spacing-unit--xl) !important;
    }

    .u-sm-paddingnRxl {
        padding-right: u($spacing-unit--xl) !important;
    }



    // Bottom

    /// None

    .u-sm-marginBn {
        margin-bottom: 0 !important;
    }

    .u-sm-paddingBn {
        padding-bottom: 0 !important;
    }


    /// Extra small

    .u-sm-marginBxs {
        margin-bottom: u($spacing-unit--xs) !important;
    }

    .u-sm-paddingBxs {
        padding-bottom: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-sm-marginBsm {
        margin-bottom: u($spacing-unit--sm) !important;
    }

    .u-sm-paddingBsm {
        padding-bottom: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-sm-marginBmd {
        margin-bottom: u($spacing-unit) !important;
    }

    .u-sm-paddingBmd {
        padding-bottom: u($spacing-unit) !important;
    }


    /// Large

    .u-sm-marginBlg {
        margin-bottom: u($spacing-unit--lg) !important;
    }

    .u-sm-paddingBlg {
        padding-bottom: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-sm-marginBxl {
        margin-bottom: u($spacing-unit--xl) !important;
    }

    .u-sm-paddingBxl {
        padding-bottom: u($spacing-unit--xl) !important;
    }



    // Left

    /// None

    .u-sm-marginLn {
        margin-left: 0 !important;
    }

    .u-sm-paddingLn {
        padding-left: 0 !important;
    }


    /// Extra small

    .u-sm-marginLxs {
        margin-left: u($spacing-unit--xs) !important;
    }

    .u-sm-paddingLxs {
        padding-left: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-sm-marginLsm {
        margin-left: u($spacing-unit--sm) !important;
    }

    .u-sm-paddingLsm {
        padding-left: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-sm-marginLmd {
        margin-left: u($spacing-unit) !important;
    }

    .u-sm-paddingLmd {
        padding-left: u($spacing-unit) !important;
    }


    /// Large

    .u-sm-marginLlg {
        margin-left: u($spacing-unit--lg) !important;
    }

    .u-sm-paddingLlg {
        padding-left: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-sm-marginLxl {
        margin-left: u($spacing-unit--xl) !important;
    }

    .u-sm-paddingLxl {
        padding-left: u($spacing-unit--xl) !important;
    }

}
