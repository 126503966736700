.Block--services {
    background-color: $cc-grey;
    padding: u(128, 0);

    @include mq($until: $viewport--sm - 1) {
        padding: u(45, 0);
    }

    .Services {

        &-intro {
            margin-bottom: u($spacing-unit--lg);

            &--label {
                font-size: u(16);
                font-weight: normal;
                color: $cc-blue;
            }

            &--title {
                font-size: u(32);
                font-weight: bold;
                line-height: normal;
                color: $cc-blue--dark;
            }
        }

        &-media {
            width: 100%;

            @include mq ($from: $viewport--md) {
                max-width: u(530);
            }
        }

        &-items {
            width: 100%;
            margin-left: auto;

            @include mq ($until: $viewport--md - 1) {
                margin-top: u($spacing-unit);
            }

            &.NoMedia{
                margin-left: 0;
                // max-width: u(750 + $spacing-unit);
                .Services-item{
                    max-width: 100%;
                }
            }
        }

        &-item {
            @include mq ($from: $viewport--md) {
                max-width: u(530);
            }

            padding-bottom: u($spacing-unit--sm);

            &:not(:first-child) {
                margin-top: u($spacing-unit--sm);
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 32, 146, 0.2);
            }

            &--title {
                color: $cc-blue;
                margin-bottom: u(5);

                font-size: u(20);
                font-weight: 600;

                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    width: u(19);
                    height: u(19);
                    min-width: u(19);
                    min-height: u(19);

                    * {
                        fill: $cc-blue;
                    }

                    polygon {
                        transition: transform .3s;
                        transform-origin: 50% 50%;
                    }
                }

                &:hover,
                &.is-active {
                    cursor: pointer;

                    svg {
                        polygon {
                            &:first-child {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }

            &--description {
                display: none;
            }
        }
    }
}
