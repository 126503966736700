/**
 * Nav
 */

.#{$nav-namespace}Nav {}

// Navigation List
.#{$nav-namespace}Nav-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
}

.#{$nav-namespace}Nav-item {
    margin: 0;
}

.#{$nav-namespace}Nav-link {
    user-select: none;
    display: block;

    &,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    // Toggle modifier
    &--toggle {
        cursor: pointer;
        display: none;

        &::before {
            content: "\2630";
            display: inline-block;
            padding-right: em($font-size + 2);
            width: 1em;
        }
    }
}

// Navigation Toggle
.#{$nav-namespace}Nav-toggle {
    display: none;
    visibility: hidden;

    // Trigger siblings when checked, and change icon & fix padding
    &:checked ~ .#{$nav-namespace}Nav-link--toggle::before {
        content: "\2715";
        padding-right: em(($font-size + 2) - 1.25);
        padding-left: em(1.25);
    }
}

// Navigation Stacked
.#{$nav-namespace}Nav--stacked {

    .#{$nav-namespace}Nav-list {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

// Navigation fit
.#{$nav-namespace}Nav--fit {

    // Apply when stacked modifier is not chained
    &:not(.#{$nav-namespace}Nav--stacked) {

        .#{$nav-namespace}Nav-item {
            flex: 1 1 auto;
        }
    }
}

// Alignment
.#{$nav-namespace}Nav--alignCenter {
    text-align: center;

    // Apply when stacked or fit modifier is not chained
    &:not(.#{$nav-namespace}Nav--stacked):not(.#{$nav-namespace}Nav--fit) {

        .#{$nav-namespace}Nav-list {
            justify-content: center;
        }
    }
}

.#{$nav-namespace}Nav--alignRight {
    text-align: right;

    // Apply when stacked or fit modifier is not chained
    &:not(.#{$nav-namespace}Nav--stacked):not(.#{$nav-namespace}Nav--fit) {

        .#{$nav-namespace}Nav-list {
            justify-content: flex-end;
        }
    }
}
