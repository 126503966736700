/**
 * Footer
 */

.Footer {
    margin-top: u(120);

    @include mq($until: $viewport--sm - 1) {
        margin-top: u(45);
    }
}

.Footer-top {
    @include font-size(15);

    color: $white;
    font-weight: 400;
    background-image: $cc-blue--image;
    padding: u(72, $spacing-unit--lg, 65, $spacing-unit--lg);

    @include mq($from: $viewport--md) {
        padding: u(72, 50, 65, 100);
    }

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .Grid-cell {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.Footer-bottom {
    @include font-size(12);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    @include mq($from: $viewport--sm) {
        flex-direction: row;
    }

    color: $cc-blue;
    padding: u(52, 0, 35, 0);
}

.MadeBy {
    @include font-size(15);

    color: $cc-blue;
    font-weight: bold;
    transition: color .3s;
    text-decoration: none;

    @include mq($until: $viewport--sm - 1) {
        margin-top: u($spacing-unit--sm * 1.5);
    }

    svg {
        width: 23px;
        height: 27px;
        margin-left: u($spacing-unit--xs);
    }

    &:hover {
        color: $black;
    }
}