/**
 * Header
 */
.show-dropdown {
    .Banner-header {
        @include mq($from: $viewport--lg) {


            position: relative;

            &.is-top {
                &::before {
                    left: -40px;

                    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
                        left: calc(((100vw - #{$structure-width}px) / 2) * -1);
                    }
                }
            }

            &.is-notTop {
                &::before {
                    left: 0;
                }
            }

            &::before {
                border-top: 1px solid $white;
                content: '';
                position: absolute;
                top: 100%;
                right: 0;
                bottom: 0;
                z-index: 15;
                width: 100vw;
                height: 100vh;
                opacity: .75;
                background-color: rgba(32, 32, 32, 0.9);
            }
        }
    }
}

.Header {}

.Nav-listWrapper {
    transition: opacity .3s, visibility .3s;
}

html:not(.show-nav) {

    .Nav--main .Nav-listWrapper {
        width: 0;
        height: 0;
        opacity: 0;
        line-height: 0;
        visibility: hidden;

        @include mq($until: $viewport--md - 1) {
            overflow: hidden;
        }

        .Nav-item {
            opacity: 0;
            visibility: hidden;
        }
    }

    @include mq($from: $viewport--lg) {

        .Nav--main .Nav-listWrapper {
            opacity: 1;
            width: auto;
            height: auto;
            display: flex;
            line-height: 1.5;
            visibility: visible;

            .Nav-item {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

html.show-nav .Nav--main {

    @include mq($until: $viewport--lg - 1) {

        .Nav-listToggle {
            position: fixed;
            top: 46px;
            z-index: 61;
            right: u($structure-responsive-gutter--r);

            @include mq($from: $viewport--md) {
                right: u($structure-responsive-gutter--r * 2);
            }
        }

        .Nav-listToggle svg {

            path:first-child {
                transform: rotate(45deg);
                transform-origin: 2px 6px;
            }

            path:nth-child(2) {
                transform: translateX(100%);
            }

            path:nth-child(3) {
                transform: rotate(-45deg);
                transform-origin: 2px 12px;
            }
        }

        .Nav-listWrapper {
            left: 0;
            right: -17px;
            bottom: 0;
            z-index: 60;
            top: 0;
            position: fixed;
            padding: u(0, $spacing-unit);
            background-image: $cc-blue--image;

            @include mq($from: $viewport--sm) {
                padding: u(0, $spacing-unit--lg);
            }

            display: flex;
            flex-direction: column;
            justify-content: center;

            overflow-y: scroll;
            transition: opacity .3s,
            visibility .3s;
        }

        .Nav-list {
            display: flex;
            align-items: center;
            flex-direction: column;

            flex-shrink: 0;
            flex-wrap: nowrap;
            margin-top: u($spacing-unit--lg);
            margin-bottom: u($spacing-unit--lg);
        }

        .Nav-item.has-dropdown .Nav-dropdown {
            padding: 0;
            display: flex;
            list-style-type: none;
            flex-direction: column;
            // margin: u($spacing-unit--sm, $spacing-unit);

            .Nav-link {
                @include font-size(18);

                color: $white;
                font-weight: 400;
                letter-spacing: 0;
                text-transform: none;

                &.is-active,
                &:hover {
                    font-weight: 400;
                }
            }
        }

        .Nav-item {
            margin-left: 0;
            text-align: center;
        }

        .Nav-link {
            @include font-size(22);

            &:hover {
                border-color: $white !important;
            }

            &::after {
                top: 1px;
                position: relative;
            }
        }

        .Nav-item+.Nav-item {
            margin-left: 0;
            margin-top: u($spacing-unit--xs * 1.5);
        }

        .Nav-list>.Nav-item>.Nav-link {
            color: $white;
        }
    }
}
