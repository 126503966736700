.Modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;
    z-index: 10;
    display: flex;
    visibility: hidden;
    background-color: rgba($white, .97);
    transition: opacity .2s, visibility .2s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}

.Modal-inner {
    position: absolute;
    top: 5vh;
    left: 0;
    right: 0;

    z-index: 3;
    width: 100%;
    max-height: 90vh;
    overflow: auto;
    line-height: 1.5;
    background-color: $cc-grey;
    padding: u($spacing-unit--lg);

    @include mq($from: $viewport--sm) {
        top: 50%;
        left: 50%;
        height: auto;
        transform: translate(-50%, -50%);
        padding: u($spacing-unit--lg * 1.5, $spacing-unit--xl);
        width: calc(100% - (#{$structure-responsive-gutter--l}px * 2));
    }

    @include mq($from: $viewport--md) {
        max-width: 760px;
        width: calc(100% - (#{$structure-gutter--l}px * 2));
        padding: u($spacing-unit--xl, $spacing-unit--xl * 1.4);
    }

    @include mq($from: $viewport--lg) {
        width: 100%;
    }
}

.Modal-close {
    position: absolute;
    top: u($spacing-unit);
    right: u($spacing-unit);

    display: flex;
    cursor: pointer;

    svg {
        width: 25px;
        height: 25px;
    }
}

.Modal-header {
    display: flex;
    flex-direction: column;

    color: $cc-blue;
    margin-bottom: u($spacing-unit);

    h2 {
        @include font-size(32);

        font-weight: bold;
        color: $cc-grey--darkest;
        font-family: $font-open-sans;
        margin-top: u($spacing-unit--sm);
    }

    svg {
        width: 35px;
        height: 35px;
        margin-right: u($spacing-unit--sm * 1.5);

        *:not([fill="none"]) {
            fill: currentColor !important;
        }

        *:not([stroke="none"]) {
            stroke: currentColor !important;
        }
    }
}

.Modal-content {
    display: flex;
    flex-direction: column;

    color: $cc-blue;
    margin-bottom: u($spacing-unit);

    .Form-label {
        @include font-size(16);

        line-height: 1.25;
        font-weight: normal;
        color: $cc-grey--darker;
        font-family: $font-lato;
    }
}