/**
 * General
 */

// Pagination
.Nav--pagination {
    width: 100%;
    margin-top: u(50);

    .Nav {

        &-list {
            justify-content: center;
        }

        &-item {
            margin: u(0, 5);
            display: flex;
            align-items: flex-end;
            justify-content: center;

            &.Dots {
                margin-bottom: u(8);
            }
        }

        &-link {
            width: u(45);
            height: u(45);
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color .5s, color .5s;
            color: $cc-blue;
            font-weight: 500;

            &:hover,
            &.is-active {
                background-color: $cc-blue;
                color: $white;
            }
        }
    }
}

.Block:last-child {
    margin-bottom: 0;
}

.Label {
    @include font-size(16);

    font-weight: 400;
    line-height: 1.25;
    color: $cc-grey--dark;
    font-family: $font-lato;
}

// Mouse
.Mouse {
    align-items: center;
    border: 2px solid $white;
    border-radius: u(12px);
    display: flex;
    flex-flow: column nowrap;
    height: u(39px);
    padding: u(5px, 0);
    width: u(22px);

    &::before {
        background-color: $white;
        border-radius: 100%;
        content: '';
        display: block;
        height: u(4px);
        width: u(4px);
        animation-name: scroll;
        animation-duration: 1.5s;
        animation-iteration-count: infinite
    }
}

@keyframes scroll {
    0% {
        opacity: 0
    }

    10% {
        transform: translateY(0);
        opacity: 1
    }

    100% {
        transform: translateY(10px);
        opacity: 0
    }
}

.Main .Block:first-child {
    margin-top: u(100);

    @include mq($until: $viewport--sm - 1) {
        margin-top: u(30);
    }
}
