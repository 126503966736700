/**
* Clients
*/

.Block--clients {
    margin: u(145, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}

.Clients-grid {
    @include grid(0px 100% 0px, 300px 100px 85px 1fr 85px);

    @include mq($from: $viewport--md) {
        @include grid(#{$structure-gutter--l}px 1fr #{$structure-gutter--l}px, 300px 100px 85px 1fr 85px);
    }

    @include mq($from: $structure-width) {
        @include grid(calc((100% - #{$structure-width--sm}px) / 2) 720px 170px 170px calc((100% - #{$structure-width--sm}px) / 2), 85px 1fr 85px);
    }

    width: 100%;
}

.Clients-background {
    @include grid-area(1, 4, 2, 6);

    @include mq($from: $structure-width) {
        @include grid-area(1, 4, 1, 4);
    }

    z-index: 1;
    position: relative;

    .Clients-backgroundGrey {
        width: 100%;
        height: 100%;
        background-color: $cc-grey;

        @include mq($until: $viewport--md - 1) {

            &::after {
                content: '';

                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;

                background-color: $cc-grey;
                width: u($structure-responsive-gutter--l);
            }
        }
    }

    &::before {
        content: '';

        background-color: $cc-grey;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: u($structure-responsive-gutter--l);

        @include mq($from: $viewport--md) {
            width: u($structure-gutter--l);
        }

        @include mq($from: $structure-width--lg + (2 * $structure-gutter--l)) {
            width: calc((100vw - #{$structure-width--lg}px)/ 2);
        }
    }

    &::after {
        content: '';

        position: absolute;
        top: 50%;
        right: u($structure-responsive-gutter--l * -1);
        bottom: 0;
        left: u($structure-responsive-gutter--l * -1);

        z-index: -1;
        height: calc(50% + 15px);
        background-image: $cc-blue--image;
        width: calc(100% + (#{$structure-responsive-gutter--l}px * 2));

        @include mq($from: $viewport--md) {
            left: auto;
            right: -15px;
            width: calc(100% + 15px + #{$structure-gutter--l}px);
        }

        @include mq($from: $structure-width--lg + (2 * $structure-gutter--l)) {
            width: calc(100% + 15px + ((100vw - #{$structure-width--lg}px)/ 2));
        }
    }
}

.Clients-object,
.Clients-image {
    display: block;
}

.Clients-object {
    @include grid-area(2, 3, 1, 3);

    @include mq($from: $structure-width) {
        @include grid-area(3, 6, 2, 3);

        align-self: center;
        -ms-grid-row-align: center;
    }

    z-index: 2;
    display: block;
    position: relative;
    text-align: center;

    img {

        @include mq($until: $viewport--sm - 1) {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }

        @include mq($from: $viewport--sm) {
            max-width: 450px;
        }

        @include mq($from: $structure-width) {
            max-width: 100%;
        }
    }
}

.Clients-content {
    @include grid-area(2, 3, 4, 5);

    @include mq($from: $structure-width) {
        @include grid-area(2, 3, 2, 3);
    }

    z-index: 2;
    position: relative;

    @include mq($until: $structure-width - 1) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    @include mq($from: $structure-width) {
        margin-right: u($spacing-unit--lg * 1.5);
    }
}

.Clients-title {
    @include font-size(32);

    max-width: 400px;
    font-weight: bold;
}

.Label + .Clients-title {
    margin-top: u($spacing-unit--xs);
}

.Clients-logos {
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;

    // margin-top: u($spacing-unit * -1);
    // margin-left: u($spacing-unit--lg * -1);

    .Slider-items {
        align-items: center;
    }
}

.Clients-logo {
    display: block;
    width: 100%;
    flex-basis: auto;
    text-align: center;
    // margin-top: u($spacing-unit--lg);
    // margin-left: u($spacing-unit--lg);

    // @include mq($from: 450) {
    //     width: calc(50% - #{$spacing-unit--lg}px);
    // }

    // @include mq($from: $viewport--md) {
    //     width: calc(33% - #{$spacing-unit--lg}px);
    // }

    svg {
        max-width: 155px;
        max-height: 155px;
        object-fit: contain;
    }

    svg, img {
        transition: filter .2s;
    }

    &:not(:hover) {

        svg, img {
            filter: gray; /* IE6-9 */
            -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
            filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
        }
    }
}