/**
 * Print
 */

@include mq($media-type: 'print') {
    *,
    *::before,
    *::after,
    *::first-letter,
    p::first-line,
    div::first-line,
    blockquote::first-line,
    li::first-line {
        background: transparent !important;
        box-shadow: none !important;
        color: $black !important;
        letter-spacing: 0;
        text-shadow: none !important;
    }

    a,
    a:visited { text-decoration: underline; }

    a[href]::after { content: " (" attr(href) ")"; }

    abbr[title]::after { content: " (" attr(title) ")"; }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]::after,
    a[href^="javascript:"]::after { content: ""; }

    pre { white-space: pre-wrap !important; }

    pre,
    blockquote {
        border: 1px solid $grey;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead { display: table-header-group; }

    tr,
    img { page-break-inside: avoid; }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 { page-break-after: avoid; }

    /**
     * General
     */

    html {
        font-family: sans-serif;
        font-size: 12pt;
    }

    .Container {
        max-width: none;
    }

    h1,
    h2,
    h3 {
        margin-bottom: 12pt;
        text-transform: none;
    }

    h1 {
        font-size: 24pt;
    }

    h2 {
        font-size: 18pt;
    }

    h3 {
        font-size: 14pt;
    }
 }
