/* DigitalPulse */
.DigitalPulse {
    color: currentColor;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    margin-left: auto;
    height: u(28px);
    font-weight: 700;
    @include mq($until: $viewport--sm - 1){
        margin: u($spacing-unit--sm, auto);
    }

    &-icon,
    &-body {
        display: block;
        fill: currentColor;
    }

    // Label
    &-label {
        margin-right: u($spacing-unit--sm);
        flex-shrink: 0;
    }

    // Object
    &-object {
        background-color: $digitalpulse-background-color;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    // Icon
    &-icon {
        flex-shrink: 0;
        height: 1.5em * $digitalpulse-icon-ratio;
        transform: translateY(5%);
        width: 1.5em;
    }

    &-icon,
    &-body {
        @include font-size(15, false);
    }

    @include mq($viewport--lg) {

        &-object {
            @include transition(#{transform, right}, 300ms, ease-out);
            // min-width: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff * $digitalpulse-text-ratio;
            min-width: u(90px);
            position: relative;
            z-index: 1;

            position: absolute;
            top: 50%;
            right: 0;
            transform: scale(1.01) translateY(-50%); // Scale Firefox fix
        }

        &:hover .DigitalPulse-object {
            color: currentColor;
            right: 100%;
        }

        &-body {
            @include transition(width, 300ms, ease-out);
            margin-left: u($spacing-unit--xs);
            transform: translateY(12.5%);

            display: flex;
            justify-content: flex-end;
            height: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff;
            width: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff * $digitalpulse-text-ratio;
        }
    }
}
