/**
 * What we do
*/

.Block--values {
    margin: u(200, 0, 145, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}

.Values-intro {
    @include font-size(22);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include mq($until: $viewport--sm - 1) {
        overflow: hidden;
    }

    @include mq($from: $viewport--md) {
        flex-direction: row;
        justify-content: flex-start;
    }

    font-weight: bold;
    margin-bottom: u($spacing-unit--lg);
}

.Values-words {
    @include font-size(45);

    font-weight: bold;
    color: $cc-blue--light;
    margin-top: u($spacing-unit--sm);

    @include mq($from: $viewport--md) {
        margin-top: -4px;
        margin-left: u(40);
    }
}



$colWidth: 454;

.Values-items {
    counter-reset: values;
}

.Values-item {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-decoration: none;
    max-width: u($colWidth);
    counter-increment: values;

    & + .Values-item {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }

    @include mq($from: $viewport--md) {
        float: left;
        width: (100% / 1060 * $colWidth);
    }

    &::before {
        @include font-size(60);

        content: attr(data-number);

        position: absolute;
        top: 50%;
        left: 0;

        z-index: 2;
        font-weight: bold;
        color: $cc-blue--light;
        font-family: $font-open-sans;
        transform: translateY(-50%) translateX(-20%);

        @include mq($from: $viewport--sm) {
            @include font-size(100);

            transform: translateY(-50%) translateX(-50%);
        }
    }

    &:nth-child(2n + 3)::before {

        @include mq($from: $viewport--md) {
            right: 0;
            left: auto;
            transform: translateY(-50%) translateX(50%);
        }
    }

    &:hover img {
        filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
        -webkit-filter: grayscale(0%);
    }
}

.Masonry-gutter {
    width: (100% - ((100 / 1060 * ($colWidth + 4)) * 2));
}

.ValueItem-title {
    @include font-size(35);

    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    margin: 0;
    z-index: 4;
    color: $white;
    font-weight: bold;
    text-align: center;
    word-wrap: break-word;
    transform: translateY(-50%);
    padding: u(0, $spacing-unit--lg);

    @include mq($from: $viewport--sm) {
        padding: u(0, $spacing-unit--xl);
    }
}

.ValueItem-object {
    background-color: $black;
    z-index: 1;
    display: block;
    overflow: hidden;
    position: relative;

    &:not(:hover) img {
        opacity: .75;
    }

    canvas {
        opacity: 0;
        visibility: hidden;
        transition: opacity .7s, visibility .7s;
        max-width: none !important;
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        width: 128%;
        height: 128%;
    }

    &:hover canvas {
        opacity: 1;
        visibility: visible;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        transition: opacity .4s, filter .4s;
        filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    }
}