/**
* Coaches
*/

.Block--coaches {
    margin-top: 145px;
    padding: u($spacing-unit--xl, 0);
    background-color: $cc-blue--dark;

    @include mq($until: $viewport--sm - 1) {
        margin-top: 45px;
        padding: u(45, 0);
    }
}

.Coaches-title {
    @include font-size(32);

    color: $white;
    max-width: 400px;
    font-weight: bold;
    font-family: $font-open-sans;
}

.Label + .Coaches-title {
    margin-top: u($spacing-unit--sm);
}

.Coaches {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    @include mq($from: $viewport--sm) {
        flex-direction: row;

        margin-left: u($spacing-unit--lg * -1);
    }

    @include mq($from: $viewport--lg) {
        margin-top: u($spacing-unit * -1);
    }
}

.Coach {
    width: 100%;
    max-width: 322px;
    flex-basis: auto;

    @include mq($until: $viewport--sm - 1) {
        margin-left: auto;
        margin-right: auto;
        margin-top: u($spacing-unit--lg);
    }

    @include mq($from: $viewport--sm) {
        margin-left: u($spacing-unit--lg);
        width: calc(50% - #{$spacing-unit--lg}px);
    }

    @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {

        &:nth-child(1) {
            margin-top: u($spacing-unit--lg);
        }

        &:nth-child(2n + 2) {
            margin-top: u($spacing-unit--lg);
        }

        &:nth-child(2) {
            margin-top: u($spacing-unit--xl);
        }
    }

    @include mq($from: $viewport--lg) {
        margin-top: u($spacing-unit--lg);
        width: calc(25% - #{$spacing-unit--lg}px);

        &:nth-child(2n + 2) {
            margin-top: u($spacing-unit--xl);
        }
    }

    &:hover {

        .Coach-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
}

.Coach-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    opacity: 0;
    color: $white;
    overflow: hidden;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    background-color: rgba($cc-blue--dark, 0.4);

    h2 {
        @include font-size(18);

        color: $white;
        font-weight: bold;
        font-family: $font-open-sans;
        margin-bottom: u($spacing-unit--sm);
    }
}

.Coach-links {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: u($spacing-unit * -1);
}

.Coach-link {
    margin-left: u($spacing-unit);

    svg {
        width: 22px;
        height: 22px;
        color: $white;
        transition: color .3s;
    }

    &:hover svg {
        color: $cc-blue--light;
    }
}

.Coach-name {
    @include font-size(35);

    color: $white;
    font-weight: bold;
    margin-top: u($spacing-unit);
    font-family: $font-open-sans;
}

.Coach-object {
    width: 100%;
    height: 100%;
    display: block;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}