/**
 * Banner
 */

 .Banner {
    @include grid(#{$structure-responsive-gutter--l}px 3fr 1fr #{$structure-responsive-gutter--r}px, 136px 1fr auto auto);

    @include mq($from: $viewport--md) {
        @include grid(#{$structure-gutter--l}px 7fr 4fr #{$structure-gutter--r}px, 136px 1fr auto auto);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid(1fr calc(#{$structure-width}px / 2) calc(#{$structure-width}px / 2) 1fr, 136px 1fr auto auto);
    }
}

.Banner-object {
    @include grid-area(1, 5, 1, 5);

    @include mq($from: $viewport--md) {
        @include grid-area(1, 5, 1, 4);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(1, 5, 1, 4);
    }

    z-index: 1;
    width: 100%;
    height: 100%;
    height: 465px;
    display: block;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.Banner-header {
    @include grid-area(2, 4, 1, 2);

    @include mq($from: $viewport--md) {
        @include grid-area(2, 4, 1, 2);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(2, 4, 1, 2);
    }

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    z-index: 11;
    position: relative;
    transition: transform .3s, opacity .3s, visibility .3s, background-color .5s, box-shadow .5s;


    &.is-notTop.is-unpinned {
        opacity: 0;
        visibility: hidden;
        // transform: translateY(-100%);
    }

    &.is-notTop.is-pinned {
        position: fixed;
        top: 0;

        opacity: 1;
        width: 100vw;
        visibility: visible;
        // transform: translateY(0);
        padding: 10px calc((100vw - #{$structure-width}px) / 2);

        background-color: $white;
    }
}

.Banner-box {
    @include grid-area(1, 5, 3, 5);

    background-color: $cc-grey;

    @include mq($from: $viewport--sm) {
        @include grid-area(1, 4, 3, 5);
    }

    @include mq($from: $viewport--md) {
        @include grid-area(1, 3, 3, 5);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(1, 3, 3, 5);
    }

    z-index: 2;
    position: relative;
}

.Banner-title {
    @include grid-area(2, 4, 3, 5);

    @include mq($from: $viewport--md) {
        @include grid-area(2, 3, 3, 5);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(2, 3, 3, 5);
    }

    align-self: center;

    z-index: 3;
    position: relative;

    h1 {
        @include font-size(32);

        color: $cc-blue;
        font-weight: bold;
        line-height: 1.11;
        margin: u($spacing-unit--lg * 1.3, $spacing-unit, $spacing-unit--lg * 1.3, 0);

        @include mq($until: $viewport--sm - 1) {
            margin: u($spacing-unit * 1.5, $spacing-unit, $spacing-unit * 1.5, 0);
        }

        @include mq($from: $viewport--md) {
            @include font-size(45);
        }
    }

    &.is-event {

        h1 {
            margin: u($spacing-unit * 1.5, $spacing-unit, $spacing-unit--sm * 1.5, 0);
        }

        .Banner-subtitle {
            margin-bottom: u($spacing-unit);
            &:last-child {
                margin-bottom: u($spacing-unit * 1.5);
            }
            + .Button {
                margin-bottom: u($spacing-unit * 1.5);
            }
        }
    }
}

.Banner-subtitle {
    @include font-size(20);

    color: $cc-blue;
    font-weight: bold;
    margin-right: u($spacing-unit);

    small {
        @include font-size(16);
    }
}




.Banner--slider {
    @include grid(#{$structure-responsive-gutter--l}px 3fr 1fr #{$structure-responsive-gutter--r}px, 136px 1fr 100px 326px 50px);

    @include mq($until: $viewport--md - 1) {
        min-height: calc(100vh + 326px + 50px);
    }

    @include mq($from: $viewport--md) {
        @include grid(#{$structure-gutter--l}px 4fr 1fr #{$structure-gutter--r}px, 136px 1fr 50px);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid(1fr 870px 380px 1fr, 136px 1fr 50px);
    }

    .Mouse {
        z-index: 2;
        align-self: flex-end;
        justify-self: center;

        margin-bottom: u($spacing-unit * 1.5);

        @include mq($from: $viewport--md) {
            @include grid-area(2, 4, 2, 3);
        }

        @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
            @include grid-area(2, 4, 2, 3);
        }
    }
}

.Slider--video {
    @include grid-area(1, 5, 4, 6);

    @include mq($until: $viewport--md - 1) {
        height: 100%;
        // padding-top: 100px;
        transform: translateY(#{u(-100px)});
        margin-right: u($structure-responsive-gutter--r);
        margin-left: u($structure-responsive-gutter--l);
        justify-self: center;

        img {
            width: 100%;
            height: 100%;
            max-width: 350px;
            object-fit: cover;
        }
    }

    @include mq($from: $viewport--md) {
        @include grid-area(2, 4, 2, 4);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(2, 5, 2, 4);
    }

    align-self: flex-end;
    justify-self: flex-end;
    -ms-grid-row-align: end;
    -ms-grid-column-align: end;

    z-index: 3;
    position: relative;
}

.Slider--button {
    @include font-size(20);

    position: absolute;
    left: -119px;
    bottom: 50px;

    display: flex;
    align-items: center;

    color: $white;
    font-weight: bold;
    padding: u(13, 24);
    letter-spacing: 1.5px;
    text-decoration: none;
    transition: color .3s, background-color .3s;
    font-family: $font-lato;
    text-transform: uppercase;
    background-color: $cc-blue--lighter;

    &:focus,
    &:active {
        color: $white;
    }

    @include mq($until: $viewport--sm - 1) {
        left: 0;
        right: 0;
        bottom: 0;

        justify-content: center;
    }

    svg {
        width: 36px;
        height: 36px;
        margin-right: u(30);
    }

    &:hover {
        color: $white;
        background-color: $cc-blue;
    }
}

.Slider--object {
    background-color: lightcoral;

    @include grid-area(1, 5, 1, 4);

    @include mq($from: $viewport--md) {
        @include grid-area(1, 5, 1, 3);
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(1, 5, 1, 3);
    }

    position: relative;

    .Slider-items {
        // height: auto !important;
    }

    .Slider-item {

        @include mq($from: $viewport--md) {
            min-height: calc(100vh - 100px);
        }
    }

    .Slider-object {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;

        img {
            position: absolute;

            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.Slider-pagination {
    @include grid-area(2, 3, 1, 4);

    z-index: 4;
    align-self: center;
    justify-self: flex-start;

    @include mq($from: $viewport--md) {
        @include grid-area(1, 2, 1, 4);

        justify-self: flex-end;
        -ms-grid-column-align: end;
    }

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        margin-right: u($spacing-unit--lg);
    }
}

.Slider--body {
    @include grid-area(2, 4, 2, 3);

    @include mq($from: $structure-width + (2 * $structure-gutter--l)) {
        @include grid-area(2, 4, 2, 3);
    }

    align-self: center;

    max-width: 100%;
    z-index: 4 !important;
    margin: u($spacing-unit--xl, $spacing-unit, $spacing-unit--xl, $spacing-unit--lg);

    @include mq($from: $viewport--md) {
        max-width: 720px;
        margin: u($spacing-unit, $spacing-unit--lg);
    }

    @include mq($from: $viewport--lg) {
        margin-left: u($spacing-unit--xl);
    }

    .Slider-items {
        height: auto !important;
    }

    .Slider-item {
        @include font-responsive(100, 430, 12, 35);

        color: $white;
        font-weight: 700;
        font-family: $font-lato;

        @include mq($from: $viewport--sm) {
            @include font-size(40);
        }

        @include mq($from: $viewport--md) {
            @include font-size(55);
        }

        @include mq($from: $viewport--lg) {
            @include font-size(65);
        }

        .Button {
            @include font-size(15);

            color: $white;
            line-height: 25px;
        }
    }
}

.Banner-object,
.Slider--object {

    &::before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: .75;
        background-image: $cc-blue--image;
    }
}

.Banner-header.is-notTop.is-pinned {
    box-shadow: 0px 2px 7px rgba($black, .08);
    padding-left: u($structure-responsive-gutter--l);
    padding-right: u($structure-responsive-gutter--l);

    @include mq($from: $viewport--md) {
        padding-left: u($structure-gutter--l);
        padding-right: u($structure-gutter--l);
    }
}

.Banner-header.is-notTop.is-pinned,
.Banner--empty {

    .Logo,
    .Logo:visited,
    .Logo:hover {
        color: $cc-blue--light;
    }

    .Nav--main {

        .Nav-link {
            color: $cc-blue--light;

            &:hover {

                &:not(.has-dropdown) {

                    @include mq($from: $viewport--md) {
                        border-color: $cc-blue--light;
                    }
                }
            }
        }

        .Nav-listToggle svg {
            transition: color .3s;
            color: $cc-blue--light;

            html.show-nav & {

                @include mq($until: $viewport--lg - 1) {
                    color: $white;
                }
            }
        }
    }


    .Nav-dropdown {

        @include mq($from: $viewport--lg) {

            &::before {
                border-bottom: 10px solid $cc-blue--light;
            }

            .Nav-item {
                background-color: $cc-blue--light;
            }

            .Nav-link {
                color: $white;

                &.is-active,
                &:active,
                &:hover {
                    color: $cc-blue;
                }
            }
        }
    }
}
