/**
 * Settings: Fonts
 */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-open-sans:                        "Open Sans", sans-serif !default; // 600, 700
$font-lato:                             "Lato", sans-serif !default; // 300, 400, 700
