/**
* Testimonials
*/

.Block--testimonials {}

.Testimonials-grid {

    @include mq($from: $viewport--md) {
        @include grid(40px 7fr 3fr 95px, 175px 175px auto 85px);
    }

    @include mq($from: $viewport--lg) {
        @include grid(95px 7fr 3fr 95px, 175px 175px auto 85px);
    }

    @include mq($from: $structure-width--lg + (2 * $structure-gutter--l)) {
        @include grid(190px 7fr 3fr 190px, 175px 175px auto 85px);
    }

    width: 100%;
}

.Testimonials-slider {

    @include mq($from: $viewport--md) {
        @include grid-area(3, 5, 2, 4);
    }

    @include mq($until: $viewport--md - 1) {
        margin: 0 auto;
        max-width: 420px;
        margin-top: u($spacing-unit--lg);
    }
}

.Testimonials-background {
    @include grid-area(1, 4, 3, 5);

    position: relative;
    background-color: $cc-grey;

    &::before {
        content: '';

        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: auto;

        height: 100%;
        background-color: $cc-grey;
        width: u($structure-responsive-gutter--l);

        @include mq($from: $viewport--md) {
            width: u($structure-gutter--l);
        }

        @include mq($from: $structure-width--lg + (2 * $structure-gutter--l)) {
            width: calc((100vw - #{$structure-width--lg}px) / 2);
        }
    }
}

.Testimonials-contentslider {

    @include mq($until: $viewport--md - 1) {
        margin: 0 auto;
        max-width: 420px;
        margin-top: u($spacing-unit--lg * 1.5);
    }

    @include mq($from: $viewport--md) {
        @include grid-area(2, 3, 3, 5);
        margin-left: u($spacing-unit * -3);

        margin-right: 100px;
        padding: u($spacing-unit, 0);
    }

    align-self: center;

    .Slider-items {
        align-items: center;

        @include mq($from: $viewport--md) {
            height: auto !important;
        }
    }
}

.Testimonials-intro {
    @include grid-area(2, 3, 1, 3);

    align-self: center;

    @include mq($until: $viewport--md - 1) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-top: u($spacing-unit--lg);
    }
}

.Testimonials-controls {
    @include grid-area(4, 5, 4, 5);

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: u($spacing-unit--xs * -1);

    @include mq($until: $viewport--md - 1) {
        position: absolute;
        left: 50%;

        z-index: 3;
        background-color: $cc-blue;
        transform: translateX(-50%) translateY(-50%);
    }
}

.Testimonials-control {
    cursor: pointer;
    padding: u($spacing-unit--sm, $spacing-unit);

    body:not(.user-is-tabbing) &:focus {
        outline: none;
    }

    svg {
        width: 10px;
        height: 16px;
        color: $white;
    }

    @include mq($from: $viewport--md) {
        padding: u($spacing-unit--xs);
        margin-left: u($spacing-unit--xs);

        svg {
            color: $cc-blue--dark;
        }
    }
}

.Testimonials-object {
    width: 100%;
    height: 100%;
    display: block;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.Testimonials-title {
    @include font-size(32);

    font-weight: bold;
    color: $cc-grey--darkest;
    font-family: $font-open-sans;
    margin-top: u($spacing-unit--sm);

    @include mq($from: $viewport--md) {
        max-width: 400px;
    }
}

.TestimonialQuote {
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);

    @include mq($from: $viewport--md) {
        padding-left: u($spacing-unit * 3);
    }

    blockquote {
        @include font-size(40);

        font-weight: bold;
        color: $cc-blue--dark;
        font-family: $font-lato;
    }
}

.TestimonialQuote-name {
    @include font-size(25);

    color: $cc-blue;
    font-weight: 300;
    font-family: $font-lato;
}

.TestimonialQuote-link {
    margin-top: u($spacing-unit);
}

.TestimonialQuote-company {
    @extend .Label;
}

.TestimonialQuote-icon {
    opacity: 0.45;
    pointer-events: none;
    height: u(69.7);
    width: u(95.21);

    @include mq($until: $viewport--md - 1) {
        margin-bottom: u($spacing-unit);
    }

    @include mq($from: $viewport--md) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
}