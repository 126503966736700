.Slider {
    position: relative;
    overflow: hidden;
    z-index: 1; /* Fix of Webkit flickering */

    &--vertical {

        > .Slider-items {
            flex-direction: column;
        }
    }

    &-items {
        box-sizing: content-box;
        display: flex;
        height: 100%;
        position: relative;
        transform: translate3d(0px, 0, 0);
        transition-property: transform;
        width: 100%;
        z-index: 1;

        // AutoHeight
        &--autoheight {

            &,
            .Slider-item {
                height: auto;
            }

            .Slider-items {
                align-items: flex-start;
                transition-property: transform, height;
            }
        }
    }

    // Android
    &--android {

        .Slider-item {
            transform: translate3d(0px, 0, 0);
        }
    }

    // MultiRow
    &--multirow {

        .Slider-items {
            flex-wrap: wrap;
        }
    }

    // FreeMode
    &--free-mode {

        .Slider-items {
            transition-timing-function: ease-out;
            margin: 0 auto;
        }
    }

    // IE10 Windows Phone 8 Fixes
    &--wp8-horizontal {
        &,
        & > .Slider-items {
            touch-action: pan-y;
        }
    }

    &--wp8-vertical {
        &,
        & > .Slider-items {
            touch-action: pan-x;
        }
    }

    // Item
    &-item {
        flex-shrink: 0;
        height: 100%;
        position: relative;
        transition-property: transform;
        width: 100%;

        &--invisible-blank {
            visibility: hidden;
        }
    }

    // Effects
    &--fade {

        .Slider-item {
            pointer-events: none;
            transition-property: opacity;

            &.is-active {
                pointer-events: auto;
            }
        }

        &.Slider--free-mode {

            .Slider-item {
                transition-timing-function: ease-out;
            }
        }
    }

    // Navigation
    &-button {
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 10;

        body:not(.user-is-tabbing) &:focus {
            outline: none;
        }

        &.is-disabled {
            opacity: .35;
            cursor: auto;
            pointer-events: none;
        }
    }
    // Pagination
    &-pagination {
        display: flex;
        justify-content: center;
        transform: translate3d(0,0,0);
        transition: .2s opacity;

        &--bullets {
            flex-direction: column;

            @include mq($from: $viewport--md) {
                z-index: 10;
            }
        }

        &--fraction {
            margin-top: u($spacing-unit--xs);

            .HomePage & {
                color: $white;

                position: absolute;
                right: u($spacing-unit);
                bottom: u($spacing-unit);
                z-index: 10;
            }
        }

        &--progressbar {
            background: $white;
            height: 1px;
            width: 57px;
        }

        &-item {

            .is-clickable & {
                cursor: pointer;
            }

            .Slider-pagination--bullets & {
                background-color: transparent;
                border-radius: 100%;
                margin-top: u(8px);
                margin-bottom: u(8px);
                transition: background-color .2s;
                height: 14px;
                width: 14px;
                border: 1px solid $white;

                &:hover {
                    background-color: rgba($white, .32);
                }

                &.is-active {
                    background-color: $white;
                    opacity: 1;
                }

                body:not(.user-is-tabbing) &:focus {
                    outline: none;
                }
            }

            .Slider-pagination--progressbar & {
                height: 100%;
                transform: scale(0);
                transform-origin: left top;
                width: 100%;

                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.Slider--modal,
.Slider-controls {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}

.Slider--modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    z-index: 53;
    height: 100%;
    max-width: none;
    background: rgba($white, .97);

    .Slider-items {

        @include mq($until: $viewport--sm - 1) {
            padding-top: u($spacing-unit--lg * 1.5);
        }
    }

    .Slider-item {
        overflow: hidden;
    }

    .Modal-inner {
        overflow: initial;
        padding: u($spacing-unit--lg);
        max-width: calc(100% - (2 * #{$spacing-unit}px));

        @include mq($until: $viewport--sm - 1) {
            overflow-y: scroll;
            max-height: calc(100vh - 200px);
        }

        @include mq($from: $viewport--sm) {
            max-width: 380px;
        }

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit--lg * 1.5);
        }

        @include mq($from: $viewport--lg) {
            max-width: 520px;
            padding: u($spacing-unit--xl);
        }

        &::before {
            @include font-size(60);

            content: attr(data-number);

            position: absolute;
            top: 0;
            left: u($spacing-unit--lg);

            z-index: 95;
            font-weight: bold;
            color: $cc-blue--light;
            font-family: $font-open-sans;
            transform: translateY(-50%);

            @include mq($from: $viewport--sm) {
                @include font-size(80);

                top: 50%;
                left: 0;
                transform: translateY(-50%) translateX(-50%);
            }

            @include mq($from: $viewport--md) {
                @include font-size(100);
            }
        }
    }
}

.Slider-controls {
    position: fixed;
    top: u($spacing-unit--sm * 1.5);
    left: 50%;

    z-index: 100;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include mq($from: $viewport--md) {
        top: 50%;
        right: 0;
        left: auto;

        transform: translateY(-50%);
        width: calc(((100vw - (100vw - 100%)) - (380px + (2 * (#{$spacing-unit--lg}px * 1.5)))) / 2);
    }

    @include mq($from: $viewport--lg) {
        width: calc(((100vw - (100vw - 100%)) - (520px + (2 * #{$spacing-unit--xl}px))) / 2);
    }

    .Slider-pagination {
        display: flex;
        flex-wrap: wrap;

        margin-right: 0;
        margin-top: u($spacing-unit--xs);
        margin-left: u($spacing-unit--xs * -1);
    }

    .Slider-pagination-item {
        @include font-size(15);

        cursor: pointer;
        font-weight: bold;
        transition: color .3s;
        color: $cc-blue--light;
        font-family: $font-open-sans;
        margin-top: u($spacing-unit--xs);
        margin-left: u($spacing-unit--xs);

        &.is-active,
        &:hover {
            color: $cc-blue;
        }
    }

    .Slider-navigation {
        display: flex;

        .Slider-button--prev,
        .Slider-button--next {
            cursor: pointer;
            padding: u($spacing-unit--xs);
        }

        svg {
            width: 8px;
            height: 14px;
            color: $cc-blue;
        }
    }
}

html.slider-modal-active {
    overflow: hidden;
}

html.slider-modal-active .Slider-modal-container {

    @include mq($until: $viewport--sm - 1) {
        background-color: rgba($white, .97);

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 100;

        display: flex;
        flex-direction: column-reverse;

        .Slider--modal,
        .Slider-controls {
            position: static;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;

            transform: none;
            background-color: transparent;
        }

        .Slider--modal {
            margin: u(0, $structure-responsive-gutter--l);
            width: calc(100% - (2 * #{$structure-responsive-gutter--l}px));
        }

        .Slider-controls {
            flex-shrink: 0;
            margin: u($spacing-unit, 0);
        }

        .Slider-items {
            padding: 0;
        }

        .Modal-inner {
            position: static;
            top: auto;
            left: auto;
            right: auto;

            max-height: none;
            overflow-y: scroll;
            padding: u($spacing-unit);
            max-height: calc(100vh - 170px);

            &::before {
                @include font-size(35);

                top: 0;
                left: 0;
                transform: none;
            }
        }
    }
}

// html.slider-modal-active .Slider-modal-container * {
//     overflow: visible !important;
// }