/**
 * Spacing: Large
 */

@include mq($from: $viewport--lg) {

    // Top

    /// None

    .u-lg-marginTn {
        margin-top: 0 !important;
    }

    .u-lg-paddingTn {
        padding-top: 0 !important;
    }


    /// Extra small

    .u-lg-marginTxs {
        margin-top: u($spacing-unit--xs) !important;
    }

    .u-lg-paddingTxs {
        padding-top: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-lg-marginTsm {
        margin-top: u($spacing-unit--sm) !important;
    }

    .u-lg-paddingTsm {
        padding-top: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-lg-marginTmd {
        margin-top: u($spacing-unit) !important;
    }

    .u-lg-paddingTmd {
        padding-top: u($spacing-unit) !important;
    }


    /// Large

    .u-lg-marginTlg {
        margin-top: u($spacing-unit--lg) !important;
    }

    .u-lg-paddingTlg {
        padding-top: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-lg-marginTxl {
        margin-top: u($spacing-unit--xl) !important;
    }

    .u-lg-paddingTxl {
        padding-top: u($spacing-unit--xl) !important;
    }



    // Right

    /// None

    .u-lg-marginRn {
        margin-right: 0 !important;
    }

    .u-lg-paddingRn {
        padding-right: 0 !important;
    }


    /// Extra small

    .u-lg-marginRxs {
        margin-right: u($spacing-unit--xs) !important;
    }

    .u-lg-paddingRxs {
        padding-right: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-lg-marginRsm {
        margin-right: u($spacing-unit--sm) !important;
    }

    .u-lg-paddingRsm {
        padding-right: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-lg-marginRmd {
        margin-right: u($spacing-unit) !important;
    }

    .u-lg-paddingRmd {
        padding-right: u($spacing-unit) !important;
    }


    /// Large

    .u-lg-marginRlg {
        margin-right: u($spacing-unit--lg) !important;
    }

    .u-lg-paddingRlg {
        padding-right: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-lg-marginRxl {
        margin-right: u($spacing-unit--xl) !important;
    }

    .u-lg-paddingnRxl {
        padding-right: u($spacing-unit--xl) !important;
    }



    // Bottom

    /// None

    .u-lg-marginBn {
        margin-bottom: 0 !important;
    }

    .u-lg-paddingBn {
        padding-bottom: 0 !important;
    }


    /// Extra small

    .u-lg-marginBxs {
        margin-bottom: u($spacing-unit--xs) !important;
    }

    .u-lg-paddingBxs {
        padding-bottom: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-lg-marginBsm {
        margin-bottom: u($spacing-unit--sm) !important;
    }

    .u-lg-paddingBsm {
        padding-bottom: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-lg-marginBmd {
        margin-bottom: u($spacing-unit) !important;
    }

    .u-lg-paddingBmd {
        padding-bottom: u($spacing-unit) !important;
    }


    /// Large

    .u-lg-marginBlg {
        margin-bottom: u($spacing-unit--lg) !important;
    }

    .u-lg-paddingBlg {
        padding-bottom: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-lg-marginBxl {
        margin-bottom: u($spacing-unit--xl) !important;
    }

    .u-lg-paddingBxl {
        padding-bottom: u($spacing-unit--xl) !important;
    }



    // Left

    /// None

    .u-lg-marginLn {
        margin-left: 0 !important;
    }

    .u-lg-paddingLn {
        padding-left: 0 !important;
    }


    /// Extra small

    .u-lg-marginLxs {
        margin-left: u($spacing-unit--xs) !important;
    }

    .u-lg-paddingLxs {
        padding-left: u($spacing-unit--xs) !important;
    }


    /// Small

    .u-lg-marginLsm {
        margin-left: u($spacing-unit--sm) !important;
    }

    .u-lg-paddingLsm {
        padding-left: u($spacing-unit--sm) !important;
    }


    /// Medium

    .u-lg-marginLmd {
        margin-left: u($spacing-unit) !important;
    }

    .u-lg-paddingLmd {
        padding-left: u($spacing-unit) !important;
    }


    /// Large

    .u-lg-marginLlg {
        margin-left: u($spacing-unit--lg) !important;
    }

    .u-lg-paddingLlg {
        padding-left: u($spacing-unit--lg) !important;
    }


    /// Extra large

    .u-lg-marginLxl {
        margin-left: u($spacing-unit--xl) !important;
    }

    .u-lg-paddingLxl {
        padding-left: u($spacing-unit--xl) !important;
    }

}
