/**
 * Alert
 */

// Success
.Alert--success {
    color: $success-text-color;
    background-color: $success-bg-color;
    border-color: $success-border-color;

    a {
        color: scale-color($success-text-color, $lightness: -25%);
    }
}

// Info
.Alert--info {
    color: $info-text-color;
    background-color: $info-bg-color;
    border-color: $info-border-color;

    a {
        color: scale-color($info-text-color, $lightness: -25%);
    }
}

// Warning
.Alert--warning {
    color: $warning-text-color;
    background-color: $warning-bg-color;
    border-color: $warning-border-color;

    a {
        color: scale-color($warning-text-color, $lightness: -25%);
    }
}

// Danger
.Alert--danger {
    color: $danger-text-color;
    background-color: $danger-bg-color;
    border-color: $danger-border-color;

    a {
        color: scale-color($danger-text-color, $lightness: -25%);
    }
}