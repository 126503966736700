/**
* Building
*/

.Block--building {
    color: $white;
    margin: u(145, 0);
    padding: u(128, 0);
    position: relative;
    background-color: $cc-blue--dark;

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
        padding: u(45, 0);
    }
}

.Building-object {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::before {
        content: '';

        position: absolute;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba($cc-blue--dark, .95);
    }
}

.Building-title {
    @include font-size(32);

    color: $white;
    max-width: 600px;
    font-weight: bold;
    margin-bottom: u($spacing-unit--sm);
}

.Label+.Building-title {
    margin-top: u($spacing-unit--sm);
}

.Building-intro {
    color: $white;
    max-width: 600px;
    line-height: 1.67;
}

.Building-items {
    @include mq($from: $viewport--sm) {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
    }

    margin-top: u($spacing-unit--lg);
    margin-left: u($spacing-unit * -1);
}

.Building-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mq($until: $viewport--sm - 1) {
        margin: u($spacing-unit, auto);
    }

    flex-basis: auto;
    max-width: 240px;
    min-height: 140px;
    text-decoration: none;
    background-color: $white;
    margin-top: u($spacing-unit);
    margin-left: u($spacing-unit);
    transition: background-color .3s;
    width: calc(100% - #{$spacing-unit}px);
    padding: u($spacing-unit);
    position: relative;


    svg {
        width: u(20);
        height: u(20);
        min-width: u(20);
        max-height: u(20);
        margin-top: u(5);
    }

    @include mq($from: $viewport--sm) {
        max-width: none;
        width: calc(33% - #{$spacing-unit}px);
    }

    @include mq($from: $viewport--lg) {
        width: calc(20% - #{$spacing-unit}px);
        margin-bottom: u($spacing-unit);

        &:nth-child(2n + 1) {
            position: relative;
            top: u($spacing-unit * -1);
        }
    }

    &.is-active,
    &:hover {
        background-color: $cc-blue--light;

        .BuildingItem-title {
            color: $white;
            transform: translateY(0);
        }
    }
}

.BuildingItem-title {
    @include font-size(18);

    margin: 0;
    font-weight: bold;
    text-align: center;
    color: $cc-blue--dark;
    transition: color .3s, transform .3s;
    transform: translateY(15px);
}

.Building-bodies {}

.Building-body {
    display: none;
}

.Building-bodyWrapper {
    @include mq($until: $viewport--sm - 1) {
        max-width: 240px;
        margin: u($spacing-unit * -1, auto);
    }

    display: flex;
    flex-wrap: wrap;

    font-weight: normal;
    margin-top: u($spacing-unit * -1);
    margin-left: u($spacing-unit * -1);

}

.Building-column {
    flex-basis: auto;
    width: 100%;

    @include mq($from: $viewport--sm) {
        width: calc(100% - #{$spacing-unit}px);
        margin-top: u($spacing-unit);
        margin-left: u($spacing-unit);
    }

    p {
        line-height: u(30);
    }

    a {
        text-decoration: none;
        color: $white;
        transition: color .5s;
        border-bottom: 1px solid $cc-blue--light;

        &:hover {
            color: $cc-blue--light;
        }
    }

    @include mq($until: $viewport--sm - 1) {
        text-align: center;
    }

    @include mq($from: $viewport--sm) {
        width: calc(50% - #{$spacing-unit}px);
    }

    @include mq($from: $viewport--md) {
        width: calc(33% - #{$spacing-unit}px);
    }

    @include mq($from: $viewport--lg) {
        width: calc(25% - #{$spacing-unit}px);
    }
}
