/**
 * Events
 */

 .Block--cta {
    margin: u(145, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}

.Cta {
    position: relative;
    @include mq($from: $viewport--lg) {
        top: -4rem;
    }
}

.Cta-body {
    position: relative;
    background-color: $cc-grey;
    @include mq($from: $viewport--lg) {
        top: 4rem;
        max-width: calc(100% - 10rem);
    }
}

.Cta-content {
    padding: 2rem;
    @include mq($from: $viewport--lg) {
        padding: 3rem 4rem 4rem;
        max-width: calc(100% - 16rem);
    }
}

.Cta-title {
    @include font-size(28);
    color: $cc-blue;
    font-family: $font-lato;
    font-weight: bold;
    line-height: 1.11;
    margin: 0;

    @include mq($from: $viewport--lg) {
        @include font-size(38);
    }
}

.Cta-text {
    margin: 1rem 0 0 0;
    color: $cc-blue;
    font-weight: 400;
}

.Cta-button {
    margin: 3rem 0 0 0;
}

.Cta-image {
    width: 100%;
    @include mq($from: $viewport--lg) {
        position: absolute;
        top: 0;
        right: 0;
        width: 26rem;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}