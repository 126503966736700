/**
 * Elements: Link
 */

// Text decoration
$link-text-decoration:                    underline !default;
$link-text-decoration--hover:             none !default;

// Colors
$link-color:                              $cc-blue !default;
$link-color--hover:                       $cc-blue--dark !default;
