/**
 * Events
 */

 .Block--events {
    margin: u(145, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}

.Events-title {
    @include font-size(28);
    color: $cc-blue;
    font-weight: bold;
    line-height: 1.11;

    @include mq($from: $viewport--md) {
        @include font-size(40);
    }
}

.Events-items {
    display: grid;
    gap: 2.5rem;
    margin-top: 3rem;
    @include mq($from: $viewport--md) {
        gap: 4rem;
        margin-top: 6rem;
    }
}

.EventsCard {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #F7F7F7;
    @include mq($from: $viewport--md) {
        grid-template-columns: 16rem 1fr;
        gap: 2rem;
        padding-bottom: 4rem;
    }
    @include mq($from: $viewport--lg) {
        gap: 6rem;
    }
}

.EventsCard-side {
    background: linear-gradient(134.72deg, #002092 0%, #1AB9C8 101.5%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem;
    font-family: $font-open-sans;
    @include mq($from: $viewport--md) {
        aspect-ratio: 1 / 1;
    }
}

.EventsCard-date {
    span {
        display: block;
        @include font-size(45);
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1;
    }
}

.EventsCard-theme {
    display: block;
    margin-top: .5rem;
    font-weight: 600;
    @include font-size(16);
    line-height: 1.3;
}

.EventsCard-main {

}

.EventsCard-title {
    margin: 0;
    @include font-size(22);
    color: $cc-blue;
    font-family: $font-open-sans;
    font-weight: bold;
    line-height: 1.2;

    @include mq($from: $viewport--md) {
        margin: -.4rem 0 0 0;
        @include font-size(26);
    }
}

.EventsCard-subtitle {
    display: block;
    margin: .75rem 0 0 0;
    @include font-size(16);
    font-family: $font-open-sans;
    color: $cc-blue;
    font-weight: 600;
    line-height: 1.2;
}

.EventsCard-tags {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin: 1.5rem 0 0 0;
    list-style-type: none;
    padding: 0;
}

.EventsCard-tag {
    border: 1px solid #D9D9D9;
    padding: 0.2rem 0.8rem;
    border-radius: 100px;
    color: #202020;
    @include font-size(13);
    font-weight: 400;
}

.EventsCard-intro {
    margin: 1.5rem 0 0 0;
}

.EventsCard-links {
    margin: 1.5rem 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;
}

.EventsCard-link {
    display: flex;
    align-items: center;
    gap: .75rem;
    svg {
        height: auto;
        width: 22px;
    }
    a {
        font-weight: 400;
        @include font-size(15);
    }
}

.EventsCard-button {
    margin: 2rem 0 0 0;
}



// .Calendar {
//     display: flex;
//     flex-direction: column;

//     @include mq($from: $viewport--md) {
//         flex-direction: row;
//     }

//     & + .Calendar {
//         margin-top: u($spacing-unit--lg);
//         padding-top: u($spacing-unit--lg);

//         border-top: 2px solid $cc-blue;
//         border-image-slice: 1;
//         border-left: 0;
//         border-right: 0;
//         border-bottom: 0;
//         border-image-source: $cc-blue--image;
//     }
// }

// .Calendar-date {
//     @include font-size(38);

//     display: flex;

//     flex-direction: column;
//     justify-content: center;

//     flex-shrink: 0;

//     width: 100%;
//     height: 175px;
//     color: $white;
//     line-height: 1;
//     font-weight: 700;
//     max-width: 175px;
//     transition: margin .3s;
//     padding: u($spacing-unit);
//     text-transform: uppercase;
//     background-color: $cc-blue;
//     font-family: $font-open-sans;

//     @include mq($from: $viewport--md) {
//         @include font-size(45);

//         height: 225px;
//         max-width: 225px;
//         margin-right: u(75);
//     }

//     @include mq($from: $viewport--lg) {
//         margin-right: u(113);
//     }
// }

// .Calendar-info {

//     @include mq($until: $viewport--md - 1) {
//         margin-top: u($spacing-unit * 1.5);
//     }
// }

// .Calendar-title {
//     @include font-size(22);

//     color: $cc-blue;
//     margin-bottom: u($spacing-unit--xs * 1.5);
// }

// .Calendar-location {
//     @include font-size(16);

//     font-weight: 600;
//     color: $cc-grey--darkest;
// }

// .Calendar-intro {

//     p {
//         margin-bottom: u(20);
//     }

//     strong {
//         @include font-size(16);

//         color: $cc-blue;
//     }
// }

// .Calendar-links {
//     display: flex;
//     flex-wrap: wrap;
//     flex-direction: column;
//     align-items: flex-start;

//     margin-left: u($spacing-unit * -1);
//     margin-top: u($spacing-unit--xs * 1.5);

//     @include mq($from: $viewport--sm) {
//         flex-direction: row;
//     }
// }

// .Calendar-link {
//     margin-top: u($spacing-unit);
//     margin-left: u($spacing-unit);
// }