/**
 * Main
 */

html {
    background-color: $background-color;
    color: $text-color;
    font-family: $font-lato;
    font-weight: 300;
    height: 100%;
}

body {
    min-height: 100%;
    overflow-x: hidden;
}
