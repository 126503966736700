/**
 * ContentBlock
 */

.Block--background {
    color: $white;
    margin: u(145, 0);
    padding: u(128, 0);
    position: relative;
    background-color: $cc-blue--dark;

    a {
        &:not(.Button) {
            color: $white;
            font-weight: 500;
        }
    }

    .Button--primary {

        &,
        &:hover {
            color: $white;
            border-color: $cc-blue--light;
        }

        &::after {
            background-color: $cc-blue--light;
        }
    }

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
        padding: u(45, 0);
    }
}

.Block--content {
    margin: u(145, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}
