/**
 * Contact
 */

.Block--contact {
    margin: u(75, 0);

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
    }
}

.Contact {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    @include mq($from: $viewport--md) {
        flex-direction: row;
        align-items: flex-start;
    }
}

.Contact-content {
    max-width: 400px;
    align-self: center;

    @include mq($from: $viewport--md) {
        max-width: 360px;
        margin-left: u(100);
    }

    @include mq($from: $viewport--lg) {
        margin-left: u(145);
    }

    .ratio-2-3 & {
        max-width: 530px;
    }
}

.Contact-object {
    max-width: 415px;

    @include mq($until: $viewport--md - 1) {
        margin-top: u($spacing-unit--lg);
    }

    @include mq($from: $viewport--md) {
        margin-left: u($spacing-unit--lg);
    }
}

.Contact-title {
    @include font-size(32);

    color: $cc-blue;
    font-weight: bold;
    font-family: $font-open-sans;
}

.Label + .Contact-title {
    margin-top: u($spacing-unit--sm);
}

.Contact-text {
    @include font-size(15);

    font-weight: normal;
    color: $cc-grey--darker;
    font-family: $font-lato;

    strong {
        @include font-size(18);

        font-weight: normal;
        color: $cc-grey--dark;
    }

    a {
        color: $cc-grey--darker;
        text-decoration: none;

        &:hover {
            color: $cc-blue;
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

.Contact-text--first {
    margin-top: u(63);
}

.Contact-text--second {
    margin-top: u(66);
}

.contactPage .Form {

    .Form-field,
    .Form-label {
        color: $cc-blue;
        border-color: $cc-blue;
        border-width: 2px;
    }

    .Form-field--select,
    .Form-field--input,
    .Form-field--textarea,
    .Form-label {
        padding-left: u($spacing-unit--sm);
    }

    .Form-field--checkbox {
        @include font-size(14);

        color: $black;
        font-weight: 300;
        line-height: 1.57;
        letter-spacing: 1px;
        font-family: $font-lato;
    }
}