/*
 * Button
 *
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */

.#{$button-namespace}Button {
    appearance: none; // 1
    background: transparent;
    border-radius: if($button-border-radius > 0, px($button-border-radius), 0);
    box-sizing: border-box; // 1
    color: inherit; // 2
    cursor: pointer;
    display: inline-block;
    font: inherit; // 3
    font-size: if($button-font-size == $font-size, null, u($button-font-size));
    line-height: inherit;
    margin: 0;
    padding: em($button-padding--t, $button-padding--r, $button-padding--b, $button-padding--l);
    position: relative;
    text-align: $button-text-align;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: normal; // 7

    color: $cc-blue;
    font-weight: 600;
    font-family: $font-open-sans;
    border-left: 6px solid $cc-blue;

    .Icon {
        margin-left: u($spacing-unit--xs * 1.5);
    }

    svg {
        width: 16px;
        height: 12px;
    }

    // Remove excess padding and border in Firefox 4+
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }


    // Button fix (Firefox/IE)
    &:focus,
    &.is-focused {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }


    // UI states
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        text-decoration: none;
    }

    &:disabled,
    &.is-disabled {
        cursor: default;
        opacity: strip-unit($button-disabled-opacity / 100);
        pointer-events: none;
    }

    &--secondary {
        background-color: $cc-blue--light;
        color: $white;
        border-left: none;
        padding: .875rem 1.5rem;
        transition: .3s background-color ease;

        .Icon {
            position: relative;
            top: -1px;
        }

        &:hover,
        &.is-hovered,
        &:focus,
        &.is-focused,
        &:active,
        &.is-active {
            background-color: darken($cc-blue--light, 4%);
            color: $white;
        }
    }
}
