/**
 * Blog
**/

.Post {
    @include font-size(16);

    color: $black;
    max-width: 760px;
    line-height: 24px;
    margin: u($spacing-unit, auto, 0, auto);

    h2, h3, h4 {
        color: $cc-blue;
        font-weight: bold;
        line-height: 1.13;
        font-family: $font-lato;
    }

    h2 {
        @include font-size(40);
    }

    h3 {
        @include font-size(24);

        margin-top: u($spacing-unit);
    }

    h4 {
        @include font-size(17);

        line-height: 1.24;
        margin-top: u($spacing-unit);
    }

    .Post-intro {
        @include font-size(22);

        font-weight: 300;
        line-height: 30px;
        margin: u($spacing-unit * 1.5, 0);
    }

    strong {
        font-weight: normal;
    }

    .Content-object {
        width: 100%;
        height: 100%;
        display: block;
    }

    .u-textMiddle {
        text-align: center;
    }

    .Media-items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        text-align: center;
        margin-top: u($spacing-unit * -1);
        margin-left: u($spacing-unit * -1);

        @include mq($from: $viewport--md) {
            flex-direction: row;
        }

        .Content-object {
            margin-top: u($spacing-unit);
            margin-left: u($spacing-unit);
        }
    }
}

.Post-link {
    margin: u($spacing-unit, 0);
}

.Quote {
    @include font-size(35);

    color: $cc-blue;
    max-width: 450px;
    font-weight: bold;
    line-height: 1.14;
    text-align: center;
    margin: u($spacing-unit--lg, auto);
}


.Bloglist {
    display: flex;
    flex-wrap: wrap;

    margin-top: u($spacing-unit--lg);
    margin-left: u($spacing-unit * -1);

    @include mq($until: $viewport--sm - 1) {
        margin-top: 0;
    }
}

.BlogItem {
    width: 100%;
    height: 390px;
    display: block;
    overflow: hidden;
    flex-basis: auto;
    position: relative;
    text-decoration: none;
    margin-top: u($spacing-unit);
    margin-left: u($spacing-unit);
    transition: color .3s, background-color .3s;

    .BlogItem-type {
        color: $cc-blue;
        font-weight: normal;
        transition: color .3s;
        background-color: $white;
        padding: u(5, 10);
    }

    .BlogItem-title {
        color: $white;
        transition: color .3s;
    }

    @include mq($from: $viewport--md) {
        width: calc(50% - #{$spacing-unit}px);

        &:nth-of-type(3) {
            width: calc(100% - #{$spacing-unit}px);
        }
    }

    @include mq($from: $viewport--lg) {
        width: calc(33% - #{$spacing-unit}px);

        &:nth-of-type(3) {
            width: calc(33% - #{$spacing-unit}px);
        }

        &:nth-of-type(5),
        &:nth-of-type(6) {
            width: calc(66% - #{$spacing-unit}px);
        }
    }

    &:not(.has-image) {

        &.is-lightblue {
            color: $white;
            background-color: $cc-blue--light;

            .BlogItem-type {
                color: $cc-blue;
            }

            &:hover {
                background-color: $cc-grey;

                .BlogItem-title {
                    color: $cc-blue--light;
                }

                .BlogItem-top svg {
                    color: $cc-blue--light;
                }

                .BlogItem-type {
                    color: $cc-blue;
                }
            }
        }

        &.is-darkblue {
            color: $white;
            background-color: $cc-blue;

            .BlogItem-type {
                color: $cc-grey--dark;
            }

            &:hover {
                background-color: $cc-grey;

                .BlogItem-title {
                    color: $cc-blue;
                }

                .BlogItem-top svg {
                    color: $cc-grey--dark;
                }

                .BlogItem-type {
                    color: $cc-grey--dark;
                }
            }
        }

        &.is-white {
            color: $cc-blue--dark;
            background-color: $cc-grey;

            .BlogItem-title {
                color: $cc-blue;
            }

            .BlogItem-top svg {
                color: $cc-grey--dark;
            }

            .BlogItem-type {
                color: $cc-grey--dark;
            }

            &:hover {
                background-color: $cc-blue;

                .BlogItem-title {
                    color: $white;
                }

                .BlogItem-top svg {
                    color: $cc-grey;
                }

                .BlogItem-type {
                    color: $cc-grey;
                }
            }
        }
    }

    &:hover .BlogItem-object::before {
        background-color: rgba($cc-grey--dark, .4);
    }
}

.BlogItem-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    z-index: 2;
    height: 100%;
    position: relative;
    padding: u($spacing-unit * 1.5);

    @include mq($from: $viewport--xl) {
        padding: u($spacing-unit--lg);
    }
}

.BlogItem-object {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    max-width: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;
        width: 100%;
        height: 100%;
        transition: background-color .3s;
        background-color: rgba($cc-grey--dark, .7);
    }
}

.BlogItem-title {
    @include font-size(32);
    @include font-responsive($viewport--xs, $viewport--xl, 20, 32);

    margin: 0;
    font-weight: bold;
    font-family: $font-open-sans;
}

.BlogItem-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        width: 20px;
        height: 20px;
        color: $white;
        transition: color .3s;
    }
}