/**
 * Nav
 */


html {

    body {


        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(39, 39, 39, 0.7);
            z-index: -1;
            opacity: 0;
            transition: opacity .5s;

            // opacity: 1;
            // z-index: 5;
        }
    }

    &.show-nav {
        @include mq($until: $viewport--lg - 1) {
            overflow: hidden;

            .Banner-header {
                background-color: transparent !important;
                box-shadow: none !important;
            }

            body {

                &::before {
                    opacity: 1;
                    z-index: 5;
                }
            }
        }
    }
}

// Dropdown

.Nav--mobile {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100vh;
    background-image: linear-gradient(to top, $cc-blue, $cc-blue--lighter);
    transform: translateX(-100%);
    transition: transform .5s;
    width: calc(100% - 60px);
    max-width: u(338);


    .show-nav & {
        transform: translateX(0);
    }


    .Nav-list {
        display: flex;
        flex-direction: column;
        position: relative;

        li,
        a {
            width: 100%;
        }
    }

    .Nav-back {
        text-transform: uppercase;
        font-size: u(14);
        letter-spacing: 1.4px;
        color: $white;
        // font-weight: bold;
        font-family: $font-lato;
        opacity: .6;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        left: u(30);
        top: u(25);
        transition: opacity .5s;
        border-bottom: 1px solid transparent;
        width: auto !important;
        transition: border-color .5s;

        &:hover {
            cursor: pointer;
            border-color: $white;
        }

        &--icon {
            width: 6px;
            height: 12px;
            transform: scale(-1);
            margin-right: u($spacing-unit--sm);
        }
    }

    .Nav-title {
        padding: u(52, 30, 60);
        text-transform: uppercase;
        font-size: u(22);
        letter-spacing: 1.4px;
        font-weight: bold;
        font-family: $font-lato;

        &, a {
            color: $white;
            text-decoration: none;
        }
    }

    .Nav-item {
        padding: u(15, 30);
        border-top: 1px solid rgba(254, 254, 254, 0.2);

        display: flex;
        justify-content: space-between;
        align-items: center;


        &:last-child {
            border-bottom: 1px solid rgba(254, 254, 254, 0.2);
        }

        &.js-mobileTogle {
            .Nav-link {
                // pointer-events: none;
            }
        }
    }

    .Nav-link {
        font-family: $font-lato;
        font-size: u(17);
        text-transform: uppercase;
        letter-spacing: 1.4px;
        color: $white;
        font-weight: normal;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;
        flex-direction: column;


        &.is-active,
        &:hover {
            font-weight: 700;

            &:not(.has-dropdown) {
                border-color: $white;
            }
        }

        &::after {
            content: attr(data-title);

            height: 0;
            opacity: 1;
            display: block;
            overflow: hidden;
            font-weight: 700;
            visibility: visible;
        }

        &--icon {
            width: 6px;
            height: 12px;
            margin-left: u(10);

            * {
                stroke: $white;
            }
        }
    }

    .Mobile-child {
        position: absolute;
        background-image: linear-gradient(to top, $cc-blue, $cc-blue--lighter);
        top: 0;
        left: 0;
        height: 100%;
        min-height: 100vh;
        background-image: linear-gradient(to top, $cc-blue, $cc-blue--lighter);
        width: 100%;
        max-width: u(338);


        transform: translateX(-100%);
        transition: transform .5s;

        &.is-active {
            transform: translateX(0);
        }
    }
}






// Main

.Nav--main {
    @include font-size(14);

    .Nav-listToggle {
        z-index: 2;
        padding: 10px 0;
        cursor: pointer;
        margin-left: u($spacing-unit--sm);

        svg {
            width: 26px;
            height: 21px;
            color: $white;

            path {
                transition: transform .3s;
            }
        }
    }

    .Nav-list {

        @include mq($from: $viewport--lg) {
            margin-left: u(-55);
        }
    }

    .Nav-item {

        @include mq($from: $viewport--lg) {
            margin-left: u(55);
        }

        &.has-dropdown {
            // position: relative;

            @include mq($from: $viewport--lg) {
                &:hover .Nav-dropdown {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }
    }

    .Nav-link {
        color: $white;
        font-weight: 400;
        letter-spacing: 1.4px;
        font-family: $font-lato;
        text-transform: uppercase;
        transition: border-color .3s;
        border-bottom: 2px solid transparent;

        @include mq($until: $viewport--md - 1) {
            font-weight: bold;
        }

        span {
            margin-left: u($spacing-unit--xs);

            svg {
                width: 6px;
                height: 9px;
                transform: rotate(90deg);
            }
        }

        &.is-active,
        &:hover {
            font-weight: 700;

            &:not(.has-dropdown) {
                border-color: $white;
            }
        }

        &::after {
            content: attr(data-title);

            height: 0;
            opacity: 1;
            display: block;
            overflow: hidden;
            font-weight: 700;
            visibility: visible;
        }
    }
}


.Nav-dropdown {
    @include mq($until: $viewport--lg - 1) {
        .Dropdown-parent {
            text-decoration: none;
            color: $white;
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0;
            text-transform: none;
            border-bottom: 2px solid transparent;
            transition: border-color .5s;
            display: block;

            &.is-active,
            &:hover {
                cursor: pointer;
                border-color: $white;
            }

            svg {
                display: none;
            }
        }
    }

    @include mq($from: $viewport--lg) {
        position: absolute;

        opacity: 0;
        list-style: none;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        pointer-events: none;
        z-index: 20;
        background-color: $white;

        padding: u($spacing-unit--lg, 0);

        .is-notTop & {
            padding: u($spacing-unit--lg, $spacing-unit);
        }

        margin: 0;
        // padding-top: 10px;

        top: 100%;
        left: 0%;
        width: 100%;
        max-width: 100%;

        .is-top & {
            // max-width: u($structure-width);
        }

        // .Wrapper{
        //     max-width: u($structure-width);
        //     margin: auto;
        //     position: relative;
        // }

        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;

            height: 45px;

            .is-top & {
                height: 65px;
            }
        }


        &::after {
            .is-top & {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: calc(((100vw - #{u($structure-width)}) / 2) * -1);
                height: 100%;
                width: 100vw;
                background-color: $white;

                @include mq ($until: ($structure-width--md + 80px) - 1) {
                    left: u(-40);
                }
            }
        }

        .Dropdown-parent {
            font-size: u(15);
            color: $cc-blue;
            font-weight: bold;
            max-width: u(220);
            margin: 0;
            padding: u(15);
            border-bottom: 1px solid $cc-blue;
            background-color: transparent;
            transition: background-color .8s, color .8s, border-color .8s;
            display: block;
            text-decoration: none;
            z-index: 2;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                width: 16px;
                height: 10px;

                * {
                    transition: stroke .8s;
                }
            }

            &.is-active {
                background-color: $cc-blue;
            }

            &:hover {
                background-color: $cc-blue--light;
                border-color: $cc-blue--light;
            }

            &:hover,
            &.is-active {
                color: $white;
                cursor: pointer;

                svg * {
                    stroke: $white;
                }
            }
        }

        .Dropdown-childs {
            position: absolute;
            right: 0;
            top: u($spacing-unit--lg);
            opacity: 0;
            transition: opacity .5s;

            .is-notTop & {
                padding-right: u($spacing-unit);
            }

            padding-bottom: u($spacing-unit--lg);
            width: calc(100% - (220px + 70px));
            display: flex;
            flex-wrap: wrap;

            @include mq($from: $viewport--xl) {
                width: calc(100% - (220px + 70px));
            }

            // display: block;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: calc((((100vw - #{u($structure-width)}) / 2) * -1) - (220px + 70px));
                width: 100vw;
                height: 100%;
                background-color: $white;
                z-index: -1;

                .is-notTop & {
                    left: calc(((220px + 70px)) * -1);

                    @include mq ($until: $viewport--xl - 1) {
                        left: calc(((220px + 70px)) * -1);
                    }
                }

                @include mq ($from: $viewport--xl, $until: ($structure-width--md + 80px) - 1) {
                    left: calc(((220px + 70px)) * -1);
                }

                @include mq ($until: $viewport--xl - 1) {
                    left: calc(((220px + 70px + 40px)) * -1);
                }
            }



            .Nav-item {
                position: relative;
                display: flex;
                align-items: flex-start;
                background-color: transparent !important;
                width: calc(100% / 2);
                padding-right: u($spacing-unit--sm);

                @include mq($from: $viewport--xl) {
                    width: calc(100% / 3);
                }

                &--title {
                    width: 100%;
                    margin-bottom: u($spacing-unit) !important;


                    .Nav-link {
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: u(-2);
                            height: 1px;
                            width: 100%;
                            background-color: $cc-blue;
                            transition: background-color .5s;
                        }

                        color: $cc-blue !important;
                        font-size: u(18);
                        font-weight: bold;
                        line-height: normal;
                        letter-spacing: normal;
                    }
                }

                .Nav-link {
                    // transition: transform .5s, color .5s;
                    // transform: translateX(-16px);
                    transition: color .5s;
                }

                // svg {
                //     width: 16px;
                //     height: 10px;
                //     min-width: 16px;
                //     min-height: 10px;
                //     opacity: 0;
                //     transition: opacity .5s, transform .5s;
                //     transform: translateX(-16px);
                //     *{
                //         stroke: $cc-blue;
                //     }
                // }

                &:hover,
                &.is-active {
                    .Nav-link {
                        // transform: translateX(10px);
                        cursor: pointer;
                        color: $cc-blue--light !important;

                        &::before {
                            background-color: $cc-blue--light;
                        }
                    }

                    // svg {
                    //     opacity: 1;
                    //     left: 0;
                    //     transform: translate(0);
                    // }
                }
            }

            .Nav-link {
                text-align: left;
                color: $cc-grey--darkest !important;
                text-transform: none;
                font-size: u(15);
                font-weight: 300;
            }

            .Nav-item,
            .Nav-link {
                margin: 0;
                // margin-bottom: u(7);
                // padding: 0;
            }

            &.is-active {
                opacity: 1;
                z-index: 1;
            }
        }



        .Nav-link {
            border: none;
            line-height: 1.5;
            text-align: center;
            text-decoration: none;
            transition: color .2s;
            // padding: u($spacing-unit--sm, $spacing-unit--sm);

            &.is-active,
            &:active,
            &:hover {
                // color: $cc-blue;
                text-decoration: none;
            }
        }

        .Nav-item {
            margin-bottom: 8px !important;
            // background-color: $white;

            &+.Nav-item {
                // margin-top: 2px;
            }
        }

    }
}


// Languages

.Nav--lang {

    .Nav-listToggle {}

    .Nav-list {}

    .Nav-item {}

    .Nav-link {}
}



// Social media
.Nav--socialMedia {
    height: u(24px);

    .Nav-list {}

    .Nav-item {

        +.Nav-item {
            margin-left: u($spacing-unit--sm);
        }
    }

    a.Nav-link {
        transition: color .2s;
        color: $white;

        .ContactPage .Contact & {
            color: $cc-grey--darker;

            &:hover {
                color: $cc-blue;
            }
        }

        &:hover {
            color: $cc-blue;
        }
    }

    svg {
        width: 18px;
        height: 18px;
        display: block;
        fill: currentColor;
    }
}
