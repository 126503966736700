/**
 * Align
 */

.u-alignBaseline {
    vertical-align: baseline !important;
}

.u-alignBottom {
    vertical-align: bottom !important;
}

.u-alignMiddle {
    vertical-align: middle !important;
}

.u-alignTop {
    vertical-align: top !important;
}