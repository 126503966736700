/**
 * Paragraph
 */

p {
    margin-bottom: u($spacing-unit--sm);

    strong {
        font-weight: 700;
    }
}

.#{$namespace}Lead {
    font-size: u($font-size * $paragraph-lead-modifier);
}
