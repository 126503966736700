/**
 * Colors: Custom
 */

// Grey
$cc-grey:                           #f7f7f7 !default;
$cc-grey--dark:                     #b3b2b2 !default;
$cc-grey--darker:                   #757575 !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);
$cc-grey--darkest:                  #202020 !default;

$cc-blue--light:                    #13bccc !default;
$cc-blue--lighter:                  #1ab9c8 !default;
$cc-blue:                           #002092 !default;
$cc-blue--dark:                     #011045 !default;
$cc-blue--image:                    linear-gradient(108deg, $cc-blue, $cc-blue--light);

// States
$success-text-color:                scale-color($green, $saturation: -50%) !default;
$success-bg-color:                  scale-color($green, $lightness: 90%, $saturation: -25%) !default;
$success-border-color:              scale-color($success-bg-color, $lightness: -5%) !default;

$info-text-color:                   scale-color($blue, $saturation: -50%) !default;
$info-bg-color:                     scale-color($blue, $lightness: 90%, $saturation: -25%) !default;
$info-border-color:                 scale-color($info-bg-color, $lightness: -5%) !default;

$warning-text-color:                scale-color($orange, $saturation: -50%) !default;
$warning-bg-color:                  scale-color($orange, $lightness: 90%, $saturation: -25%) !default;
$warning-border-color:              scale-color($warning-bg-color, $lightness: -5%) !default;

$danger-text-color:                 scale-color($red, $saturation: -50%) !default;
$danger-bg-color:                   scale-color($red, $lightness: 90%, $saturation: -25%) !default;
$danger-border-color:               scale-color($danger-bg-color, $lightness: -5%) !default;

// Lay-out
$text-color:                        $black !default;
$background-color:                  $white !default;
$border-color:                      $cc-grey !default;

$footer-background-color:           $white !default;