/**
 * Heading
 */

a {
    color: $link-color;
    text-decoration: $link-text-decoration;

    &:hover,
    &:focus,
    &:active {
        color: $link-color--hover;
        text-decoration: $link-text-decoration--hover;
    }
}
