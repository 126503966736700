/**
 * Icon
 */

.Icon {
    display: inline-flex;
    align-self: center;
    flex-shrink: 0;

    svg {
        fill: currentColor;
    }

    &.Icon--baseline svg {
        top: 2px;
        position: relative;
    }
}
