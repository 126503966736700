/**
 * Mixin: Font-responsive
 */

@mixin font-responsive($min-vw, $max-vw, $min-fontsize, $max-fontsize) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-fontsize);
    $u4: unit($max-fontsize);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            @include mq($until: $min-vw - 1) {
                font-size: u($min-fontsize);
            }

            @include mq($from: $min-vw, $until: $max-vw - 1) {
                font-size: calc(#{$min-fontsize}px + #{strip-unit($max-fontsize - $min-fontsize)} * ((100vw - #{$min-vw}px) / #{strip-unit($max-vw - $min-vw)}));
            }

            @include mq($from: $max-vw) {
                font-size: u($max-fontsize)
            }
        }
    }
}
