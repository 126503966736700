/**
 * What we do
 */

.Block--whatwedo {
    color: $white;
    margin: u(145, 0);
    padding: u(128, 0);
    position: relative;
    background-color: $cc-blue--dark;

    a {
        &:not(.Button){
            color: $white;
            font-weight: 500;
        }
    }

    @include mq($until: $viewport--sm - 1) {
        margin: u(30, 0);
        padding: u(45, 0);
    }

    &::before {
        content: '';

        position: absolute;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba($cc-blue--dark, .95);
    }
}

.Whatwedo-object {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &::before {
        content: '';

        position: absolute;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba($cc-blue--dark, .95);
    }
}

.Whatwedo-title {
    @include font-size(20);

    color: $white;
    line-height: 1;
    max-width: 600px;
    font-weight: bold;
    margin-bottom: u($spacing-unit--sm);
}

.Whatwedo-intro {
    color: $white;
    max-width: 600px;
    line-height: 1.67;
}

.Whatwedo-items {
    display: flex;
    flex-wrap: wrap;
    counter-reset: what-we-do;

    margin-top: u($spacing-unit * 1.5);
    margin-left: u($spacing-unit--lg * -1);

    @include mq($from: $viewport--md) {
        flex-wrap: nowrap;
    }

    @include mq($from: $viewport--xl) {
        margin-left: u($spacing-unit--xl * -1);
    }
}

.Whatwedo-item {
    width: 100%;
    flex-basis: auto;

    position: relative;
    counter-increment: what-we-do;
    margin-top: u($spacing-unit--lg);
    margin-left: u($spacing-unit--lg);

    @include mq($from: $viewport--lg) {
        width: 33%;
        margin-top: u($spacing-unit);
    }

    @include mq($from: $viewport--xl) {
        margin-left: u($spacing-unit--xl);
    }

    &::before {
        @include font-size(15);
        content: counter(what-we-do, upper-roman);

        position: absolute;
        top: -20px;
        left: 0;

        font-weight: bold;
        color: $cc-grey--dark;
        font-family: $font-lato;
    }
}

.WhatwedoItem-title {
    margin-bottom: u($spacing-unit);
}

.Whatwedo-effect {
    position: absolute;

    top: calc(100% + 20px);
    left: 20px;

    width: 2px;
    height: 2px;
    background-color: $cc-blue--light;

    @include mq($from: $viewport--md) {
        top: calc(100% + 40px);
        left: 40px;
    }
}