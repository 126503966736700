/**
 * Media
 */

.Block--media {
  margin: u(145, 0);

  @include mq($until: $viewport--sm - 1) {
    margin: u(30, 0);
  }

  &.is-fullwidth {
    .Media-object {
      flex-shrink: 0;
    }

    &.Block--mediaRight .Media-object {
      @include mq($from: $viewport--md) {
        margin-right: u($structure-gutter--l * -1);
      }

      @include mq(
        $from: $structure-width--sm +
          (
            2 * $structure-gutter--l,
          )
      ) {
        margin-right: calc((100vw - #{$structure-width--sm}px) / -2);
      }

      @include mq(
        $from: $structure-width--lg +
          (
            2 * $structure-gutter--l,
          )
      ) {
        margin-right: calc(
          (#{$structure-width--lg}px - #{$structure-width--sm}px) / -2
        );
      }

      @include mq(
        $from: $structure-width--xl +
          (
            2 * $structure-gutter--l,
          )
      ) {
        margin-right: calc(
          (#{$structure-width--xl}px - #{$structure-width--sm}px) / -2
        );
      }
    }

    &.Block--mediaLeft .Media-object {
      @include mq($from: $viewport--md) {
        margin-left: u($structure-gutter--l * -1);
      }

      @include mq(
        $from: $structure-width--sm +
          (
            2 * $structure-gutter--l,
          )
      ) {
        margin-left: calc((100vw - #{$structure-width--sm}px) / -2);
      }

      @include mq(
        $from: $structure-width--xl +
          (
            2 * $structure-gutter--l,
          )
      ) {
        margin-left: calc(
          (
              #{$structure-width--xl}px - #{$structure-width--sm}px +
                (#{$structure-gutter--l}px * 2)
            ) / -2
        );
      }
    }

    .Media-content {
      max-width: 760px;
    }
  }
}

.Media {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .Block--mediaLeft & {
    flex-direction: column-reverse;
  }

  @include mq($from: $viewport--md) {
    flex-direction: row;
    align-items: flex-start;

    .Block--mediaLeft & {
      flex-direction: row-reverse;
    }
  }
}

.Media-content {
  max-width: 400px;

  @include mq($from: $viewport--md) {
    max-width: 360px;
  }

  .ratio-2-3 & {
    max-width: 530px;
  }

  .eventPage & {
    @include mq($from: $viewport--md) {
      max-width: 360px;
      align-self: flex-start;
    }
  }

  .aboutPage & {
    @include mq($from: $viewport--md) {
      max-width: 400px;
      align-self: flex-start;
    }
  }

  .HomePage .Block--mediaLeft.ratio-6-5 & {
    @include mq($from: $viewport--md) {
      margin-right: 75px;
    }
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1em;
  }
  table tr {
    display: table-row;
  }

  table td {
    display: table-cell;
    border: 1px solid #e0e0e0 !important;
    padding: 0.5em;
  }
}

.Media-object {
  max-width: 415px;

  @include mq($until: $viewport--md - 1) {
    margin-top: u($spacing-unit--lg);

    .Block--mediaLeft & {
      margin-top: 0;
      margin-bottom: u($spacing-unit--lg);
    }
  }

  @include mq($from: $viewport--md) {
    margin-left: u($spacing-unit--lg);

    .Block--mediaLeft & {
      margin-left: 0;
      margin-right: u($spacing-unit--lg);
    }
  }

  .ratio-6-5 & {
    max-width: 530px;
  }
}

.Media-title {
  @include font-size(32);

  line-height: 42px;

  .eventPage & {
    @include font-size(22);

    color: $cc-blue;
    font-weight: bold;
    line-height: 1.36;
    font-family: $font-lato;
  }

  strong {
    color: $white;
    padding: 0 12px;
    background-color: $cc-blue;

    /* Needs prefixing */
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;

    &.on-newline {
      left: -12px;
      position: relative;
    }
  }
}

.Media-links {
  display: flex;
  flex-direction: column;

  margin-top: u($spacing-unit--sm);
}

.Media-link {
  margin-top: u($spacing-unit--sm);
}

.Label + .Media-title {
  margin-top: u($spacing-unit--sm);
}
