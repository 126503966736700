/**
 * Size: Extra Small
 */

@include mq($until: $viewport--sm - 1) {

    .u-xs-size1of12 {
        flex-basis: auto !important;
        width: calc(100% * 1 / 12) !important;
    }

    .u-xs-size1of10 {
        flex-basis: auto !important;
        width: 10% !important;
    }

    .u-xs-size1of8 {
        flex-basis: auto !important;
        width: 12.5% !important;
    }

    .u-xs-size1of6,
    .u-xs-size2of12 {
        flex-basis: auto !important;
        width: calc(100% * 1 / 6) !important;
    }

    .u-xs-size1of5,
    .u-xs-size2of10 {
        flex-basis: auto !important;
        width: 20% !important;
    }

    .u-xs-size1of4,
    .u-xs-size2of8,
    .u-xs-size3of12 {
        flex-basis: auto !important;
        width: 25% !important;
    }

    .u-xs-size3of10 {
        flex-basis: auto !important;
        width: 30% !important;
    }

    .u-xs-size1of3,
    .u-xs-size2of6,
    .u-xs-size4of12 {
        flex-basis: auto !important;
        width: calc(100% * 1 / 3) !important;
    }

    .u-xs-size3of8 {
        flex-basis: auto !important;
        width: 37.5% !important;
    }

    .u-xs-size2of5,
    .u-xs-size4of10 {
        flex-basis: auto !important;
        width: 40% !important;
    }

    .u-xs-size5of12 {
        flex-basis: auto !important;
        width: calc(100% * 5 / 12) !important;
    }

    .u-xs-size1of2,
    .u-xs-size2of4,
    .u-xs-size3of6,
    .u-xs-size4of8,
    .u-xs-size5of10,
    .u-xs-size6of12 {
        flex-basis: auto !important;
        width: 50% !important;
    }

    .u-xs-size7of12 {
        flex-basis: auto !important;
        width: calc(100% * 7 / 12) !important;
    }

    .u-xs-size3of5,
    .u-xs-size6of10 {
        flex-basis: auto !important;
        width: 60% !important;
    }

    .u-xs-size5of8 {
        flex-basis: auto !important;
        width: 62.5% !important;
    }

    .u-xs-size2of3,
    .u-xs-size4of6,
    .u-xs-size8of12 {
        flex-basis: auto !important;
        width: calc(100% * 2 / 3) !important;
    }

    .u-xs-size7of10 {
        flex-basis: auto !important;
        width: 70% !important;
    }

    .u-xs-size3of4,
    .u-xs-size6of8,
    .u-xs-size9of12 {
        flex-basis: auto !important;
        width: 75% !important;
    }

    .u-xs-size4of5,
    .u-xs-size8of10 {
        flex-basis: auto !important;
        width: 80% !important;
    }

    .u-xs-size5of6,
    .u-xs-size10of12 {
        flex-basis: auto !important;
        width: calc(100% * 5 / 6) !important;
    }

    .u-xs-size7of8 {
        flex-basis: auto !important;
        width: 87.5% !important;
    }

    .u-xs-size9of10 {
        flex-basis: auto !important;
        width: 90% !important;
    }

    .u-xs-size11of12 {
        flex-basis: auto !important;
        width: calc(100% * 11 / 12) !important;
    }


    // Properties

    .u-xs-sizeFit {
        flex-basis: auto !important;
    }

    .u-xs-sizeFill {
        flex: 1 1 0% !important;
        flex-basis: 0% !important;
    }

    .u-xs-sizeFillAlt {
        flex: 1 1 auto !important;
        flex-basis: auto !important;
    }

    .u-xs-sizeFull {
        width: 100% !important;
    }

}