/**
 * Event
 */

.Event {
    transition: margin .3s;
    margin-top: u($spacing-unit);

    @include mq($from: $viewport--lg) {
        margin-top: u(100);
        display: grid;
        grid-template-columns: 2fr minmax(26rem, 1fr);
        grid-gap: u($spacing-unit*2);
        grid-template-rows: auto;
    }
    @include mq($from: $viewport--xl) {
        grid-gap: u($spacing-unit*3);
    }

    h1 {
        @include font-size(40);

        color: $cc-blue;
        line-height: 45px;
        font-weight: bold;
        margin-bottom: u($spacing-unit--lg);
    }

    .Event-left,
    .Event-right {
        margin-bottom: u($spacing-unit*2);
        @include mq($from: $viewport--lg) {
            margin-bottom: 0;
        }
    }
}

.Event-right {

    &Wrapper {
        @include font-size(16);

        line-height: 1.25;
        font-weight: bold;
        color: $cc-grey--darker;
        background-color: $cc-grey;
        padding: u($spacing-unit * 1.5);

        @include mq($until: $viewport--md - 1) {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;

            > div {
                width: 50%;
                flex-basis: auto;
            }
        }

        @include mq($until: $viewport--sm - 1) {
            display: flex;
            flex-direction: column;

            > div {
                width: 100%;
                flex-basis: auto;
            }
        }

        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit--lg);
        }

        h2 {
            @include font-size(36);

            margin: 0;
            color: $cc-blue;
            line-height: 45px;
            font-weight: bold;
            font-family: $font-lato;

            @include mq($from: $viewport--lg) {
                @include font-size(40);
            }
        }
    }

    &Button {
        padding-left: u($spacing-unit * 1.5);
        @include mq($from: $viewport--lg) {
            padding-left: u($spacing-unit--lg);
        }
    }

    &Cta {
        background-color: $cc-blue;
        color: $white;
        padding: u($spacing-unit * 1.5);
        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit--lg);
        }
        &Inner {
            > svg {
                display: block;
                max-width: 46px;
                margin-bottom: u($spacing-unit);
                @include mq($from: $viewport--lg) {
                    margin-bottom: u($spacing-unit * 2);
                }
                path {
                    fill: rgba($white, .3)
                }
            }
            h3 {
                @include font-size(22);

                line-height: 45px;
                font-weight: bold;
                font-family: $font-lato;

                @include mq($from: $viewport--lg) {
                    @include font-size(36);
                }
            }
        }
    }
}

.Event-bottom {

    &Wrapper {
        @include font-size(16);

        line-height: 1.25;
        font-weight: bold;
        color: $cc-grey--darker;
        background-color: $cc-grey;
        padding: u($spacing-unit * 1.5);

        @include mq($from: $viewport--lg) {
            margin-right: u(-$spacing-unit*2)
        }
        @include mq($from: $viewport--xl) {
            margin-right: u(-$spacing-unit*3)
        }

        h2 {
            color: $cc-blue;
            font-weight: bold;
            font-family: $font-lato;

            @include mq($from: $viewport--lg) {
                @include font-size(32);
            }
        }
    }

    &List {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: u($spacing-unit);
        @include mq($from: $viewport--md) {
            margin: 0 u(-$spacing-unit/2) u($spacing-unit*2);
        }
    }
    &ListItem {
        width: 100%;
        @include mq($from: $viewport--md) {
            width: 50%;
            padding: 0 u($spacing-unit/2);
        }

    }
    &ListItemInner {
        padding: u($spacing-unit/1.5) 0;
        border-bottom: 2px solid $cc-blue;
        h4 {
            font-weight: 400;
            color: $cc-blue;
            margin-bottom: .45rem;
        }
        p {
            font-size: 1rem;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
}

.Slot {

    & + .Slot {
        margin-top: u($spacing-unit--lg);
    }
}

.Slot-time {
    @include font-size(16);

    color: #b3b2b2;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: u(5);
}

.Slot-title {
    color: $cc-blue;
    font-weight: bold;
    font-family: $font-lato;
    font-size: 22px !important;
    line-height: 30px !important;
    margin: u(0, 0, $spacing-unit--xs, 0) !important;
}

.Slot-info {
    @include font-size(16);

    font-weight: 600;
    color: $cc-grey--darkest;
    font-family: $font-open-sans;

    a {
        text-decoration: none;
        color: $cc-blue--light;

        &:hover {
            color: $cc-blue;
        }
    }
}

.Slot-content {
    margin-top: u($spacing-unit--sm)
}

.Event-upcoming {
    padding: u(100, 0);
    background-color: $cc-grey;
    margin-top: u($spacing-unit--lg * 1.25);

    .Block--events {
        margin: 0;
    }
}

.Event-upcoming--title {
    @include font-size(40);

    color: $cc-blue;
    font-weight: bold;
    line-height: 1.13;
    font-family: $font-lato;
    margin-bottom: u($spacing-unit--lg * 1.2);
}

/*
    Temporary fix ...
 */
.EventDetailPage:not(.isSecured) {
    form {
        .Form-item.Form-item--list {
            display: none;
        }
    }
}
